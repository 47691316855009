import React, { useContext, useState } from "react";
import { authFetch } from "../../Authentication/AuthenticationProvider";
import { ApiContext } from "../../Context/ApiContext";
import moment from "moment";
import Linkify from "linkify-react";
import "moment/locale/sv";

const NotificationCard = (props) => {
  const { getV1Path } = useContext(ApiContext);
  const [active, setActive] = useState(props.active);
  const [deleted, setDeleted] = useState(false);

  const LinkDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  async function markAsRead(notificationId) {
    await authFetch(getV1Path(`/notification/inactive/${notificationId}`), {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
    });
  }

  async function deleteNotification(notificationId) {
    await authFetch(getV1Path(`/notification/${notificationId}`), {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
    });
  }
  function modifyTimestamp(notificationDate) {
    const date = new Date(notificationDate);

    moment.locale("sv");
    return moment.utc(date).local().startOf("seconds").fromNow();
  }

  if (deleted) {
    return null;
  }

  return (
    <>
      <div className="ladda-dr-du-br">
        <div className="all-content">
          <div className="menu-mobile-listitem">
            <div className="menu-mobile-listitem-wrapper">
              <div className="frame-wrapper">
                {active ? (
                  <div className="frame-2">
                    <div className="ellipse-wrapper">
                      <div className="ellipse-active"></div>
                    </div>
                    <div
                      className="frame-3"
                      onClick={() => {
                        markAsRead(props.id);
                        setActive(false);
                      }}
                    >
                      <div className="text-wrapper-2">{props.title}</div>
                      <p className="p">
                        <Linkify componentDecorator={LinkDecorator}>
                          {props.body}
                        </Linkify>
                      </p>

                      <div className="text-wrapper-3">
                        {modifyTimestamp(props.timestamp)}
                      </div>
                    </div>
                    <button
                      className="action-trash"
                      onClick={() => {
                        deleteNotification(props.id);
                        setDeleted(true);
                      }}
                    >
                      <img className="icon" src="img/trash.svg" />
                    </button>
                  </div>
                ) : (
                  <div className="frame-2">
                    <div className="ellipse-wrapper">
                      <div className="ellipse"></div>
                    </div>
                    <div className="frame-3">
                      <div className="text-wrapper-7">{props.title}</div>
                      <p className="p">
                        <Linkify componentDecorator={LinkDecorator}>
                          {props.body}
                        </Linkify>
                      </p>

                      <div className="text-wrapper-3">
                        {modifyTimestamp(props.timestamp)}
                      </div>
                    </div>
                    <button
                      className="action-trash"
                      onClick={() => {
                        deleteNotification(props.id);
                        setDeleted(true);
                      }}
                    >
                      <img className="icon" src="img/trash.svg" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="divider">
          <img className="line" src="img/line-47-4.svg" />
        </div>
      </div>
    </>
  );
};
export default NotificationCard;
