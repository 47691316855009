import React, { useContext, useState } from "react";
import { HashRouter as Router, useHistory } from "react-router-dom";

import { ApiContext } from "../../Context/ApiContext";
import InfoModal from "../../Common/InfoModal";

const ResetPassword = () => {
  const [username, setUsername] = useState("");
  const [errorTextHide, setErrorTextHide] = useState(true);
  const [errorText, setErrorText] = useState(null);
  const { getV1Path } = useContext(ApiContext);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const onSubmitClick = async (e) => {
    e.preventDefault();
    fetch(getV1Path("/reset_password/user"), {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: username,
      }),
    })
      .then((response) => {
        if (response.status !== 200) {
          response.json().then((data) => {
            setErrorText("Reset password failed due to: " + data.message);
            setErrorTextHide(false);
          });
        } else {
          history.push("/login");
        }
      })
      .catch((error) => {
        setErrorText("No connection to server due to: " + error);
        setErrorTextHide(false);
      });
  };

  const history = useHistory();
  return (
    <>
      <Router>
        <div className="login-flow-resetpassword">
          <div className="div">
            <div className="stacked-logos">
              <img className="boolean-group" src="img/boolean-group.svg" />
              <img className="logos-symbol-large" src="img/large.png" />
            </div>
            <button className="buttons" type="submit" onClick={onSubmitClick}>
              <div className="button-label">Återstall lösenord</div>
            </button>
            <div className="overlap-group">
              <div className="group">
                <div className="text-input-field">
                  <div className="label">MAC ID</div>

                  <input
                    className="frame"
                    type="user"
                    onChange={handleUsernameChange}
                    value={username}
                  />
                </div>
              </div>
            </div>
            <div className="text-wrapper-3">Återstall lösenord</div>
            {!errorTextHide ? (
              <InfoModal
                header="Password reset has failed: "
                text={errorText}
              ></InfoModal>
            ) : null}
            <div className="link">
              <button className="button" onClick={() => history.push("/login")}>
                <img className="icon" src="img/icon.svg" />
                <div className="label-2">Tillbaka till inloggning</div>
              </button>
            </div>

            <div className="text-wrapper-2">Saver - Pilot</div>
          </div>
        </div>
      </Router>
    </>
  );
};

export default ResetPassword;
