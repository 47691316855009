import React, { useContext, useEffect } from "react";
import { UserContext } from "../../Context/UserContext";
import ChangeUserData from "./ChangeUserData";

const ModifyUserData = () => {
  const { userContext } = useContext(UserContext);

  useEffect(() => {}, []);
  if (userContext.username === null) {
    return null;
  }
  return (
    <>
      <ChangeUserData username={userContext.username} />
    </>
  );
};

export default ModifyUserData;
