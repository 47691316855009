import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { ApiProvider } from "./Context/ApiContext";
import { ClearCacheProvider, useClearCacheCtx } from "react-clear-cache";
import "./scss/main.scss";

const ClearCache = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
  if (process.env.NODE_ENV === "development") {
    console.log("Clearing cache in dev environment");
    emptyCacheStorage();
  }
  if (!isLatestVersion) {
    emptyCacheStorage();
  }
  return null;
};

ReactDOM.render(
  <ClearCacheProvider filename="build.json">
    <ApiProvider>
      <ClearCache />
      <App />
    </ApiProvider>
  </ClearCacheProvider>,

  document.getElementById("root")
);
registerServiceWorker();
