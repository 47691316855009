import { createAuthProvider } from "react-token-auth";

const { apiUrl } = window.runConfig;

export const [useAuth, authFetch, login, logout] = createAuthProvider({
  accessTokenKey: "access_token",
  onUpdateToken: (token) =>
    fetch(apiUrl + "/api/v1/refresh", {
      method: "POST",
      body: token.access_token,
    }).then((r) => r.json()),
});
