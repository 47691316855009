import React, { useState, useContext, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import LogoutButton from "../LogoutButton";
import { UserContext } from "../../Context/UserContext";
import DropDownSpotPrice from "../DropDownSpotPrice";
import InfoModal from "../../Common/InfoModal";
import { authFetch } from "../../Authentication/AuthenticationProvider";
import { ApiContext } from "../../Context/ApiContext";
import CloseModalButton from "../../Common/CloseModalButton";

const options = [
  { value: "SN1", label: "SE1 - Lulea" },
  { value: "SN2", label: "SE2 - Sundsvall" },
  { value: "SN3", label: "SE3 - Stockholm" },
  { value: "SN4", label: "SE4 - Malmo" },
];

const Start = () => {
  const { userContext } = useContext(UserContext);
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState(
    options[options.findIndex((x) => x.value === userContext.spotPrice)] ||
      options[2]
  );
  const { getV1Path } = useContext(ApiContext);
  const [errorTextHide, setErrorTextHide] = useState(true);
  const [errorTextHeader, setErrorTextHeader] = useState(null);
  const [errorText, setErrorText] = useState(null);
  const [fuseSize, setFuseSize] = useState(null);
  const [powerGuard, setPowerGuard] = useState(null);
  const [dailyConsumptionLimit, setDailyConsumptionLimit] = useState(null);
  const changeUserDataScreen = (e) => {
    history.push("/home/userData");
  };
  const OpenManualPage = (e) => {
    history.push("/manual");
  };

  const changeFuseSize = (e) => {
    setFuseSize(e.target.value);
  };
  const changePowerGuard = (e) => {
    setPowerGuard(e.target.value);
  };
  const changeDailyConsumptionLimit = (e) => {
    setDailyConsumptionLimit(e.target.value);
  };

  const onSubmitChange = async (value, parameter) => {
    const url = getV1Path(`/userInput/${parameter}/${value}`);
    await authFetch(url, {
      method: "PATCH",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setErrorTextHeader("Operation Succeed");
            setErrorText(data.message);
            setErrorTextHide(false);
          });
        } else {
          response.json().then((data) => {
            setErrorTextHeader("Operation Failed");
            setErrorText(
              "User Input could not be collected due: " + data.message
            );
            setErrorTextHide(false);
          });
        }
      })

      .catch((error) => {
        console.log(error);
        setErrorTextHeader("Operation Failed");
        setErrorText("No connection to server due to: " + error);
        setErrorTextHide(false);
      });
  };

  useEffect(() => {
    async function userInputReqest() {
      const url = getV1Path(`/userInput/all`);

      await authFetch(url, {
        method: "GET",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => {
              setFuseSize(data.fuseSize);
              setDailyConsumptionLimit(data.dailyConsumptionLimit);
              setPowerGuard(data.powerGuard);
            });
          } else {
            response.json().then((data) => {
              setErrorTextHeader("Operation Failed");
              setErrorText(
                "User Input could not be collected due: " + data.message
              );
              setErrorTextHide(false);
            });
          }
        })

        .catch((error) => {
          console.log(error);
          setErrorTextHeader("Operation Failed");
          setErrorText("No connection to server due to: " + error);
          setErrorTextHide(false);
        });
    }

    userInputReqest();
  }, [errorTextHide]);

  if (userContext.login === null) {
    return <Redirect to="/home/userData" />;
  }

  return (
    <>
      <div className="my-ACCOUNT">
        <div className="div">
          <div className="overlap">
            <div className="all-content">
              <div className="subitems">
                <div className="div-2">
                  <div className="elpriser-heading">
                    <div className="text-wrapper-5">Användarprofil</div>
                  </div>
                  <div className="div-3">
                    <div className="frame-6">
                      <div className="text-wrapper-6">Användarnamn</div>
                      <div className="select-option">{userContext.login}</div>
                    </div>
                    <div className="frame-6">
                      <div className="text-wrapper-7">Device ID (MAC ID)</div>
                      <div className="select-option">
                        {userContext.username}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divider">
                  <img className="line-2" src="img/line-47-4.svg" />
                </div>
                <div className="div-2">
                  <div className="elpriser-heading">
                    <div className="text-wrapper-8">App inställningar</div>
                  </div>
                </div>
                <div className="div-2">
                  <div className="div-3">
                    <div className="text-wrapper-9">Välj ditt elprisomräde</div>
                    <div className="dropdown-frame">
                      <DropDownSpotPrice
                        setSelectedOption={setSelectedOption}
                        selectedOption={selectedOption}
                        options={options}
                        setErrorText={setErrorText}
                        setErrorTextHide={setErrorTextHide}
                        setErrorTextHeader={setErrorTextHeader}
                      />
                    </div>
                    <div className="div-3">
                      <div className="text-wrapper-9">Välj MAX ström (A)</div>
                      <div className="frame-settings-input">
                        <input
                          data-testid="fuse-size-input"
                          className="dropdown-2"
                          type="number"
                          onChange={changeFuseSize}
                          value={fuseSize || NaN}
                        />
                        <button
                          className="button-2"
                          data-testid="ok-current"
                          onClick={() => onSubmitChange(fuseSize, "fuseSize")}
                        >
                          <div className="button-label">Spara val</div>
                        </button>
                      </div>
                    </div>
                    <div className="div-3">
                      <div className="text-wrapper-9">Välj MAX effekt (kW)</div>
                      <div className="frame-settings-input">
                        <input
                          className="dropdown-2"
                          type="number"
                          onChange={changePowerGuard}
                          value={powerGuard || NaN}
                        />
                        <button
                          className="button-2"
                          onClick={() =>
                            onSubmitChange(powerGuard, "powerGuard")
                          }
                        >
                          <div className="button-label">Spara val</div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="div-3">
                    <div className="text-wrapper-9">
                      Välj MAX förbrukning / dag (kWh)
                    </div>
                    <div className="frame-settings-input">
                      <input
                        className="dropdown-2"
                        type="number"
                        onChange={changeDailyConsumptionLimit}
                        value={dailyConsumptionLimit || NaN}
                      />
                      <button
                        className="button-2"
                        onClick={() =>
                          onSubmitChange(
                            dailyConsumptionLimit,
                            "dailyConsumptionLimit"
                          )
                        }
                      >
                        <div className="button-label">Spara val</div>
                      </button>
                    </div>
                  </div>
                  <div className="elprisomrade">
                    <div className="line-wrapper">
                      <img className="line-2" src="img/line-47-4.svg" />
                    </div>
                    <div className="elpriser-heading-wrapper">
                      <div className="elpriser-heading">
                        <div className="text-wrapper-8">Hjälp</div>
                      </div>
                    </div>
                    <div className="link">
                      <button className="button" onClick={OpenManualPage}>
                        <img className="img-2" src="img/icon.svg" />
                        <div className="label">Gå till användarmanualen</div>
                      </button>
                    </div>
                  </div>
                  <div className="elprisomrade-2">
                    <div className="line-wrapper">
                      <img className="line-2" src="img/line-47-4.svg" />
                    </div>
                    <div className="elpriser-heading-wrapper">
                      <div className="elpriser-heading">
                        <div className="text-wrapper-8">
                          Inloggningsuppgifter
                        </div>
                      </div>
                    </div>
                    <div className="link">
                      <button className="button" onClick={changeUserDataScreen}>
                        <img className="img-2" src="img/icon.svg" />
                        <div className="label">
                          Ändra dina inloggningsuppgifter
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="elprisomrade-3">
                    <div className="line-wrapper">
                      <img className="line-2" src="img/line-47-4.svg" />
                    </div>
                    <LogoutButton />
                  </div>
                </div>
                {!errorTextHide ? (
                  <InfoModal header={errorTextHeader} text={errorText}>
                    <CloseModalButton setCloseModal={setErrorTextHide} />
                  </InfoModal>
                ) : null}
              </div>
            </div>
            <div className="molecules-navigation">
              <div className="div-wrapper">
                <div className="title-wrapper">
                  <div className="title-3">Mitt konto</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="MyAccount">
        <div className="div">
          <div className="stacked-logos">
            <img className="boolean-group" src="img/boolean-group.svg" />
            <img className="logos-symbol-large" src="img/large.png" />
          </div>
          <div className="dropdown-frame">
            <DropDownSpotPrice
              setSelectedOption={setSelectedOption}
              selectedOption={selectedOption}
              options={options}
              setErrorText={setErrorText}
              setErrorTextHide={setErrorTextHide}
            />
          </div>
          <div className="interaction-buttons">
            <LogoutButton />
          </div>
          <button
            className="button-label-wrapper-2"
            onClick={changeUserDataScreen}
          >
            <div className="text-wrapper-1">Ändra inloggningsuppgifter</div>
          </button>
          <button className="button-label-wrapper" onClick={OpenManualPage}>
            <div className="text-wrapper-2">Manual</div>
          </button>
          {!errorTextHide ? (
            <InfoModal
              header="Spot price area change failed due to: "
              text={errorText}
            ></InfoModal>
          ) : null}
          <div className="text-wrapper">SAVER - Pilot</div>
          <div className="dropdown-title">Välj elprisområde: </div>
        </div>
      </div>
      */}
    </>
  );
};

export default Start;
