import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import React, { useContext } from "react";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import Login from "./Authentication/Login";
import { useAuth } from "./Authentication/AuthenticationProvider";
import { UserContext, UserProvider } from "./Context/UserContext";
import HomeRouter from "./components/HomeRouter";
import Start from "./components/Screens/Start";
import Manual from "./components/Screens/Manual";
import ResetPassword from "./components/Screens/ResetPassword";
// import ResetPassword from "./components/Screens/ChangeUserData";
// import NotificationPage from "./components/Screens/NotificationsPage";

const history = createBrowserHistory();

ReactGA.initialize("UA-000000-01");
ReactGA.pageview(window.location.pathname + window.location.search);

history.listen(function (location) {
  ReactGA.pageview(window.location.pathname + window.location.search);
});

const App = () => {
  const { userContext, setUserData, removeUserData } = useContext(UserContext);

  const [logged] = useAuth();
  console.log(userContext);

  return (
    <>
      <HashRouter>
        <UserProvider value={{ userContext, setUserData, removeUserData }}>
          <Switch>
            {logged && (
              <>
                {/* <Route path="/notifications" component={Notifications}/> */}
                <Route path="/manual" component={Manual} />
                <Route path="/home">
                  <HomeRouter />
                </Route>
                <Redirect to="/home/mainpage" />
              </>
            )}
            {!logged && (
              <>
                <Route path="/start" component={Start} />
                <Route path="/login" component={Login} />
                <Route path="/manual" component={Manual} />
                <Route path="/resetpassword" component={ResetPassword} />
                {/* <Route path="/notifications" component={Notifications}/> */}
                <Redirect to="/start" />
                {/* <Route exact path="/login" component={Login} />
                <Redirect to="/login" /> */}
              </>
            )}
          </Switch>
        </UserProvider>
      </HashRouter>
    </>
  );
};

export default App;

// export default class App extends React.Component {
//   render() {
//     return (
//       <Router history={history}>
//
//       </Router>
//     );
//   }
// }
