import React, { useContext, useEffect } from "react";
import { authFetch } from "../../Authentication/AuthenticationProvider";
import { ApiContext } from "../../Context/ApiContext";
import { UserContext } from "../../Context/UserContext";
const TopBar = ({
  setDisplayNotification,
  notifications,
  setNotifications,
}) => {
  const { userContext } = useContext(UserContext);
  const { getV1Path } = useContext(ApiContext);

  function checkActive() {
    if (notifications && notifications.length > 0) {
      for (const [index, notification] of notifications.entries()) {
        console.log(index);
        if (notification.active === 1) {
          return true;
        }
      }
    }
    return false;
  }

  useEffect(() => {
    async function notificationRequest(user) {
      const url = getV1Path(`/notifications/${user}`);

      await authFetch(url, {
        method: "GET",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) =>
          response.status === 200 ? response.json() : { data: [] }
        )
        .then((data) => setNotifications(data.data));
    }
    // if(props.username !== "") {
    notificationRequest(userContext.username);
    const timer = setInterval(async () => {
      notificationRequest(userContext.username);
    }, 5000);
    return () => clearInterval(timer);
    // }
  }, [userContext.username]);

  const OpenLinkRapporteraEttProblem = () => {
    window.open(
      "https://www.netigate.se/ra/s.aspx?s=1200015X422157719X93298",
      "_blank"
    );
  };

  return (
    <div className="topbar">
      <div className="div">
        <div className="molecules-navigation">
          <div className="group">
            <div className="overlap">
              <div className="div">
                <button
                  className="overlap-group"
                  onClick={() => setDisplayNotification(true)}
                >
                  <img className="alarm" src="img/alarm.svg" />
                  {/* Implement logic here for green dot on notificiations */}
                  {checkActive() ? <div className="frame"></div> : null}
                </button>
                <div
                  className="hyperlink-button"
                  onClick={OpenLinkRapporteraEttProblem}
                >
                  <div className="action-help-wrapper">
                    <div className="action-help">
                      <img className="icon" src="img/alert.svg" />
                    </div>
                  </div>
                  <div className="text-wrapper">Rapportera ett problem</div>
                </div>
              </div>

              {/* <div className="text-wrapper">Rapportera ett problem</div> */}
            </div>
          </div>
          {/* <div class="frame-wrapper">
            <div className="frame-2">
              <div className="title">Välkommen!</div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
