import React, { useContext, useState } from "react";
import { HashRouter as Router, useHistory } from "react-router-dom";

import { login } from "./AuthenticationProvider";
import { ApiContext } from "../Context/ApiContext";
import { UserContext } from "../Context/UserContext";
import InfoModal from "../Common/InfoModal";
import ModalButton from "../Common/ModalButton";
import { setupPush } from "../Helpers/serviceWorkerHelpers";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorTextHide, setErrorTextHide] = useState(true);
  const [errorText, setErrorText] = useState(null);
  const { getV1Path } = useContext(ApiContext);
  const { setUserData } = useContext(UserContext);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onSubmitClick = (e) => {
    e.preventDefault();
    fetch(getV1Path("/login"), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((token) => {
        if (!token.access_token) {
          InfoModal("Error", "Fel inloggningsuppgifter");
          setErrorText("Fel inloggningsuppgifter");
          setErrorTextHide(false);
          return;
        }
        login(token);
        setUserData(username);
        const url = getV1Path(`/subscribe/${username}`);
        setupPush(url);
      })
      .catch((error) => {
        setErrorText("Ingen anslutning till server pga: " + error);
        setErrorTextHide(false);
      });
  };
  const history = useHistory();
  const onCloseClick = (e) => {
    history.push("/start");
  };

  return (
    <>
      <Router>
        <div className="login-flow">
          <div className="div">
            <button className="close-button" onClick={onCloseClick}>
              <img className="navigation-close" src="img/close.svg" />
            </button>
            <div className="stacked-logos">
              <img className="boolean-group" src="img/boolean-group.svg" />
              <img className="logos-symbol-large" src="img/large.png" />
            </div>
            <button
              name="login-button"
              className="buttons"
              type="submit"
              onClick={onSubmitClick}
            >
              <div className="button-label">Logga in</div>
            </button>
            <div className="overlap-group">
              <div className="group">
                <div className="text-input-field">
                  <div className="label">Användarnamn</div>
                  {!(errorText === "Fel inloggningsuppgifter") ? (
                    <input
                      className="frame"
                      type="user"
                      onChange={handleUsernameChange}
                      value={username}
                    />
                  ) : (
                    <input
                      className="error-frame"
                      type="user"
                      onChange={handleUsernameChange}
                      value={username}
                    />
                  )}
                </div>
              </div>
              <div className="text-input-field-wrapper">
                <div className="text-input-field">
                  <div className="label">Lösenord</div>
                  {!(errorText === "Fel inloggningsuppgifter") ? (
                    <input
                      className="frame"
                      type="password"
                      onChange={handlePasswordChange}
                      value={password}
                    />
                  ) : (
                    <input
                      className="error-frame"
                      type="password"
                      onChange={handlePasswordChange}
                      value={password}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="text-wrapper">Logga in</div>
            {!errorTextHide ? (
              <InfoModal header="Inloggningen misslyckades: " text={errorText}>
                <ModalButton
                  path="/resetpassword"
                  text="Återställ loginuppgifter"
                />
              </InfoModal>
            ) : null}
            <div className="link">
              <button
                className="button"
                onClick={() => history.push("/resetpassword")}
              >
                <img className="icon" src="img/icon.svg" />
                <div className="label-2">Återställ loginuppgifter</div>
              </button>
            </div>
            <div className="text-wrapper-2">SAVER - Pilot</div>
          </div>
        </div>
      </Router>
    </>
  );
};

export default Login;
