const moment = require("moment-timezone");
export function convertToUTCFromTimeZone(timezone, dateString) {
  const timezoneDate = moment.tz(dateString, timezone);

  const utcDate = timezoneDate.clone().utc();

  // const localTimeOffset = moment().utcOffset();

  // const localDateTime = utcDate.clone().add(localTimeOffset, "minutes");

  return utcDate.toISOString();
}

export function convertTimeToUTC(timeArray) {
  if (timeArray === undefined) return [];
  const convertedTime = timeArray.map((el) => {
    return new Date(el + "Z");
  });
  return convertedTime;
}

export function transofrmToHHMM(date) {
  const dateObject = new Date(date);
  let hour = "" + dateObject.getHours();
  let min = "" + dateObject.getMinutes();
  if (hour.length < 2) hour = "0" + hour;
  if (min.length < 2) min = "0" + min;
  return hour + ":" + min;
}
export function getSpotPriceTimeZoneLabel(timestamp, timeZone, countryCode) {
  return (
    new Date(timestamp).toString() ===
    new Date(
      new Date(currentTimeRoundedHours()).toLocaleString(countryCode, {
        timeZone: timeZone,
      })
    ).toString()
  );
}

export function daysBeforeDate(days, date) {
  return new Date(date - days * (86400 * 1000)).toISOString();
}

export function daysAfterDate(days, date) {
  return new Date(date + days * (86400 * 1000)).toISOString();
}

export function dayBefore() {
  // return new Date(new Date("2024-03-11") - 86400 * 1000).toISOString();
  return new Date(Date.now() - 86400 * 1000).toISOString();
}

export function dayAfter() {
  return new Date(Date.now() + 86400 * 1000).toISOString();
}
export function threeDaysAfter() {
  return new Date(Date.now() + 86400 * 1000 * 4).toISOString();
}

export function currentTime() {
  return new Date(Date.now()).toISOString();
}

export function minus10Min() {
  return new Date(Date.now() - (86400 * 1000) / 144).toISOString();
}

export function minus1H() {
  return new Date(Date.now() - (86400 * 1000) / 24).toISOString();
}

export function plus1H() {
  return new Date(Date.now() + (86400 * 1000) / 24).toISOString();
}

export function minus6H() {
  return new Date(Date.now() - (86400 * 1000) / 4).toISOString();
}
export function plus6H() {
  return new Date(Date.now() + (86400 * 1000) / 4).toISOString();
}

function currentTimeRoundedHours() {
  const date = new Date();
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.toISOString();
}

export function startDateFormat(setStartDate) {
  const currentDate = new Date();
  const d = new Date(currentDate.setDate(currentDate.getDate() - 2));

  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  setStartDate([year, month, day].join("-"));
}

export function endDateFormat(setEndDate) {
  const currentDate = new Date();
  const d = new Date(currentDate.setDate(currentDate.getDate() + 1));
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  setEndDate([year, month, day].join("-"));
}

export function formatDate(dateToFormat) {
  const d = new Date(dateToFormat);

  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
