export async function setupPush(url) {
  // TODO: Generate new vapid key for server and implement in Node.js backend as well
  const publicVapidKey =
    "BOd2EQ8LTe3KAgMX9lWwTlHTRzv1Iantw50Mw6pUnsNr3pcxl8iglUs-YlQEQLo4UbJk9oyXs_BxgyAe0TCqKME";
  const register = await navigator.serviceWorker.getRegistration();
  console.log("setup push triggred");
  if (register) {
    console.log("seting up subscription");
    const subscription = await register.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: publicVapidKey,
    });

    await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(subscription),
    });
    console.log("trigger fetch");
  }
}
