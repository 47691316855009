import React, { useState } from "react";
import Plot from "react-plotly.js";
import { transofrmToHHMM } from "../Helpers/FunctionTimeHelpers";
import Lottie from "react-lottie-player";

const HistoryDataGraph = ({
  graphData,
  isDay,
  isCost,
  isLoading,
  // transformationFactor,
}) => {
  const [hoveredPoint, setHoveredPoint] = useState(undefined);
  const [hoveredData, setHoveredData] = useState(undefined);
  const [hoveredCosts, setHoveredCosts] = useState(undefined);

  const weekDays = ["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"];

  const dispatchColor = {
    consumption: "#2071B5",
    production: "#8FB8DA",
    costs: "red",
  };

  const dispatchGrpahType = {
    consumption: "bar",
    production: "bar",
    costs: "scatter",
  };

  const graphLayout = {
    consumption: {
      unit: "kWh",
      title: "Förbrukning",
    },
    production: {
      unit: "kWh",
      title: "Förbrukning",
    },
    costs: {
      unit: "SEK",
      title: "Cost",
    },
  };

  function objectIsEmpty(object) {
    return Object.keys(object) === 0;
  }

  function alignTooltip(position) {
    if (position < 200) {
      return "0%";
    }
    return "-100%";
  }

  function getToolTip() {
    if (hoveredData === undefined) {
      return null;
    }
    return (
      <div
        style={{
          left: `${hoveredPoint?.event.clientX}px`,
          top: `${hoveredPoint?.event.clientY}px`,
          position: "fixed",
          width: "130px",
          height: "55px",
          transform: `translateX(${alignTooltip(hoveredPoint?.event.clientX)})`,
        }}
        className="tooltip"
      >
        <div className="price">
          <div className="label-tooltip">
            {" "}
            {hoveredData.y}{" "}
            {hoveredCosts
              ? graphLayout.costs.unit
              : graphLayout.consumption.unit}
          </div>
        </div>
        <div className="time">
          <img className="clock" src="img/clock.svg" />
          <div className="text-wrapper-tooltip">
            {" "}
            {isDay
              ? transofrmToHHMM(hoveredData.x)
              : weekDays[hoveredData.x]}{" "}
          </div>
        </div>
      </div>
      // </div>
    );
  }

  function splitNegativeConsumptionTrace(data) {
    const consumptionPositive = { x: [], y: [] };
    const consumptionNegative = { x: [], y: [] };
    if (data && data.consumption && data.consumption.y) {
      data.consumption.y.forEach((value, index) => {
        if (value >= 0) {
          consumptionPositive.y.push(value);
          consumptionPositive.x.push(data.consumption.x[index]);
        } else {
          consumptionNegative.y.push(value);
          consumptionNegative.x.push(data.consumption.x[index]);
        }
      });
      if (data.costs) {
        return {
          costs: data.costs,
          consumption: consumptionPositive,
          production: consumptionNegative,
        };
      }
      return {
        consumption: consumptionPositive,
        production: consumptionNegative,
      };
    }
    return data;
  }

  function transformDataPoints(data) {
    if (data === undefined) return null;
    const transformedData = data.map((value) => {
      return Math.round(value * 100) / 100;
    });
    return transformedData;
  }
  function convertTimeToUTC(timeArray) {
    if (isDay) {
      if (timeArray === undefined) return [];
      const convertedTime = timeArray.map((el) => {
        return new Date(el + "Z");
      });
      return convertedTime;
    }
    return timeArray;
  }

  function setUpTraces() {
    const tracesData = [];
    const transformedGraphData = splitNegativeConsumptionTrace(graphData);
    Object.keys(transformedGraphData).map((el) => {
      const data = {
        x: objectIsEmpty(transformedGraphData[el])
          ? []
          : convertTimeToUTC(transformedGraphData[el].x),
        y: objectIsEmpty(transformedGraphData[el])
          ? []
          : transformDataPoints(transformedGraphData[el].y),
        type: dispatchGrpahType[el],
        // mode: "lines+points",
        marker: { color: dispatchColor[el], size: 1 },
        hovertemplate: "<extra></extra>",
        showlegend: false,
        yaxis: el === "costs" ? "y2" : "y",
      };

      tracesData.unshift(data);
      return null;
    });
    return tracesData;
  }

  if (isLoading) {
    console.log("should display");
    return (
      <>
        <Lottie
          path="https://unpkg.com/@vf-dds/vf-dds-vanilla/src/examples/indicators/spinner/lottie/spinner.json"
          loop
          play
          speed={1}
          style={{
            width: "88px",
            height: "88px",
            position: "relative",
            top: "50%",
            left: "35%",
          }}
        />
      </>
    );
  }

  return (
    <>
      <Plot
        data={setUpTraces()}
        config={{ displayModeBar: false }}
        layout={{
          width: 374,
          height: 240,
          margin: { l: 20, r: 0, b: 0, t: 0 },
          yaxis: {
            automargin: true,
            title: {
              text:
                graphLayout.consumption.title +
                " [" +
                graphLayout.consumption.unit +
                "]",
              standoff: 15,
            },

            showgrid: false,
            // zeroline: false,
            zeroline: true,
            zerolinecolor: "#969696",
            zerolinewidth: 4,
            uirevision: "time",
            side: "right",
          },
          yaxis2: {
            automargin: true,
            title: {
              text:
                graphLayout.costs.title + " [" + graphLayout.costs.unit + "]",
              standoff: 15,
            },
            visible: isCost,
            overlaying: "y",

            showgrid: false,
            // zeroline: false,
            zeroline: false,
            zerolinecolor: "#969696",
            zerolinewidth: 4,
            uirevision: "time",
            side: "left",
          },
          xaxis: {
            automargin: true,
            title: { text: "Tid" },
            type: isDay ? "date" : "-",
            uirevision: "time",
            tickmode: isDay ? "auto" : "array",
            tickvals: [0, 1, 2, 3, 4, 5, 6],
            ticktext: ["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"],
          },
          hovermode: "closest",
        }}
        onClick={(e) => {
          if (!e.points) return;
          if (!e.points.length) return;
          if (e.points[0].pointIndex === undefined) return;
          setHoveredPoint(e);
          setHoveredData({ x: e.points[0].x, y: e.points[0].y });
          setHoveredCosts(e.points[0].fullData.type !== "bar");
        }}
        onDoubleClick={() => {
          setHoveredPoint(undefined);
          setHoveredData(undefined);
          setHoveredCosts(undefined);
        }}
      />
      {hoveredPoint && getToolTip()}
    </>
  );
};

export default HistoryDataGraph;
