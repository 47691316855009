import React, { useState } from "react";
import Plot from "react-plotly.js";
import { transofrmToHHMM } from "../Helpers/FunctionTimeHelpers";

const SimplePlotlyGraph = ({
  graphData,
  graphLayout,
  transformationFactor,
}) => {
  const [hoveredPoint, setHoveredPoint] = useState(undefined);
  const [hoveredData, setHoveredData] = useState(undefined);

  const dispatchColor = {
    TOTAL: "black",
    PRODUCTION: "red",
    L1: "blue",
    L2: "green",
    L3: "red",
  };

  function alignTooltip(position) {
    if (position < 200) {
      return "0%";
    }
    return "-100%";
  }

  function getToolTip() {
    if (hoveredData === undefined) {
      return null;
    }
    return (
      <div
        style={{
          left: `${hoveredPoint?.event.clientX}px`,
          top: `${hoveredPoint?.event.clientY}px`,
          position: "fixed",
          width: "130px",
          height: "55px",
          transform: `translateX(${alignTooltip(hoveredPoint?.event.clientX)})`,
        }}
        className="tooltip"
      >
        <div className="price">
          <div className="label">
            {" "}
            {hoveredData.y} {graphLayout.unit}
          </div>
        </div>
        <div className="time">
          <img className="clock" src="img/clock.svg" />
          <div className="text-wrapper"> {transofrmToHHMM(hoveredData.x)} </div>
        </div>
      </div>
      // </div>
    );
  }

  function transformDataPoints(data) {
    if (data === undefined) return null;
    const transformedData = data.map((value) => {
      return value * transformationFactor;
    });

    return transformedData;
  }

  function objectIsEmpty(object) {
    return Object.keys(object) === 0;
  }

  function convertTimeToUTC(timeArray) {
    if (timeArray === undefined) return [];
    const convertedTime = timeArray.map((el) => {
      return new Date(el + "Z");
    });
    return convertedTime;
  }

  function setUpTraces() {
    const tracesData = [];
    Object.keys(graphData).map((el) => {
      const data = {
        x: objectIsEmpty(graphData[el])
          ? []
          : convertTimeToUTC(graphData[el].sensorDataX),
        y: objectIsEmpty(graphData[el])
          ? []
          : transformDataPoints(graphData[el].sensorDataY),
        type: "scatter",
        mode: "lines+points",
        marker: { color: dispatchColor[el], size: 1 },
        hovertemplate: "<extra></extra>",
        showlegend: false,
      };

      tracesData.unshift(data);
      return null;
    });
    return tracesData;
  }

  return (
    <>
      <Plot
        data={setUpTraces()}
        config={{ displayModeBar: false }}
        layout={{
          width: 374,
          height: 240,
          margin: { l: 20, r: 0, b: 0, t: 0 },
          yaxis: {
            automargin: true,
            title: {
              text: graphLayout.title + " [" + graphLayout.unit + "]",
              standoff: 15,
            },
            showgrid: false,
            // zeroline: false,
            zeroline: true,
            zerolinecolor: "#969696",
            zerolinewidth: 4,
            uirevision: "time",
            side: "right",
          },
          xaxis: {
            automargin: true,
            title: { text: "Tid" },
            type: "date",
            uirevision: "time",
          },
          hovermode: "closest",
        }}
        onClick={(e) => {
          console.log(e);
          if (!e.points) return;
          if (!e.points.length) return;
          if (e.points[0].pointIndex === undefined) return;
          setHoveredPoint(e);
          setHoveredData({ x: e.points[0].x, y: e.points[0].y });
        }}
        onDoubleClick={() => {
          setHoveredPoint(undefined);
          setHoveredData(undefined);
        }}
      />
      {hoveredPoint && getToolTip()}
    </>
  );
};

export default SimplePlotlyGraph;
