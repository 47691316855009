import React, { useState, useEffect, useContext } from "react";
import { authFetch } from "../Authentication/AuthenticationProvider";
import { ApiContext } from "../Context/ApiContext";
import {
  getMaxValueFromArray,
  extractSensorID,
} from "../Helpers/SensorTransformationHelpers";

import {
  dayBefore,
  dayAfter,
  getSpotPriceTimeZoneLabel,
} from "../Helpers/FunctionTimeHelpers";
import { UserContext } from "../Context/UserContext";

const Gauge = ({
  color,
  unit,
  spotPrice,
  type,
  svgSize,
  transformationFactor,
}) => {
  const [value, setValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);
  const { getV1Path } = useContext(ApiContext);
  const { userContext } = useContext(UserContext);

  function getSensorValue(data) {
    if (data.currentValue) {
      setMaxValue(data.maxValue !== 0 ? data.maxValue : null);
      setValue(data.currentValue);
    }
  }

  function getSpotPriceValue(data) {
    if (data.spot_price && data.spot_price.x.length > 0) {
      for (const [index, timestamp] of data.spot_price.x.entries()) {
        if (getSpotPriceTimeZoneLabel(timestamp, "Europe/Stockholm", "sv-SV")) {
          setValue(data.spot_price.y[index] * transformationFactor);
          setMaxValue(
            getMaxValueFromArray(data.spot_price.y) * transformationFactor
          );
          break;
        }
      }
    }
  }

  useEffect(() => {
    async function sensorRequest(startDate, endDate, type) {
      let url, method, body;
      switch (type) {
        case "CurrentConsumption":
          url = getV1Path(
            `/getCurrentPower/${startDate}/${endDate}/${extractSensorID(
              userContext.AEPD
            )}/${extractSensorID(userContext.IAEP)}`
          );
          method = "GET";
          body = null;
          break;

        case "YesterdayConsumption":
          url = getV1Path(
            `/getTodayConsumption/${startDate}/${endDate}/${extractSensorID(
              userContext.AEI
            )}/${extractSensorID(userContext.AEE)}`
          );
          method = "GET";
          body = null;
          break;

        case "SpotPrice":
          url = getV1Path(`/spotPrice`);
          method = "POST";
          body = JSON.stringify({
            from_date: dayBefore(),
            to_date: dayAfter(),
            price_area: userContext.spotPrice,
          });
          break;
        default:
          throw new Error(`Invalid gauge type: ${type}`);
      }

      await authFetch(url, {
        method: method,
        mode: "cors",
        headers: { "Content-Type": "application/json" },
        body: body,
      })
        .then((response) => response.json())
        .then((data) => {
          switch (type) {
            case "CurrentConsumption":
              getSensorValue(data);
              break;
            case "YesterdayConsumption":
              getSensorValue(data);
              break;
            case "SpotPrice":
              getSpotPriceValue(data);
              break;
            default:
              throw new Error(`Invalid gauge type: ${type}`);
          }
        });
    }

    spotPrice !== null
      ? sensorRequest(dayBefore(), dayAfter(), type)
      : sensorRequest(dayBefore(), dayAfter(), type);
    const timer = setInterval(
      async () => {
        spotPrice !== null
          ? sensorRequest(dayBefore(), dayAfter(), type)
          : sensorRequest(dayBefore(), dayAfter(), type);
      },
      spotPrice !== null ? 1000 * 60 * 60 : 10000
    );
    return () => clearInterval(timer);
  }, []);

  if (value === null) {
    setMaxValue(100);
    setValue("N/A");
  }

  const strokeWidth = (svgSize / 10) * 2; // This is the thickness of the gauge relative to the SVG size
  const radius = svgSize / 2 - strokeWidth / 2; // This makes the radius relative to the SVG size
  const circumference = 2 * Math.PI * radius;
  const offset =
    typeof value === "string" || maxValue === null
      ? circumference - (0 / 100) * circumference
      : circumference - (value / maxValue) * circumference;

  return (
    <div className="overlap-group-2">
      <svg width="100%" height="100%" viewBox={`0 0 ${svgSize} ${svgSize}`}>
        <circle
          stroke={color}
          opacity={0.3}
          fill="transparent"
          strokeWidth={strokeWidth}
          r={radius}
          cx={svgSize / 2}
          cy={svgSize / 2}
        />
        <circle
          stroke={color}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          r={radius}
          cx={svgSize / 2}
          cy={svgSize / 2}
          transform={`rotate(-90, ${svgSize / 2}, ${svgSize / 2})`}
        />
      </svg>
      <p className="element">
        <span className="span">
          {parseFloat(value).toFixed(2)}
          <br />
        </span>{" "}
        <span className="text-wrapper-4">{unit}</span>
      </p>
    </div>
  );
};

export default Gauge;
