import { useHistory } from "react-router-dom";

import React, { useRef, useState, useEffect, useContext } from "react";

import { ApiContext } from "../../Context/ApiContext";
import { authFetch } from "../../Authentication/AuthenticationProvider";

import { UserContext } from "../../Context/UserContext";
import InfoModal from "../../Common/InfoModal";
import LogoutButton from "../LogoutButton";

// ToDO: Add logic for the reset password. Assign logic to both buttons.
// Create error message if the passwords do not match.
// Create error message if the username is already taken.
// Create error message if the username and password are the same.

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const ChangeUserData = (props) => {
  const { getV1Path } = useContext(ApiContext);
  const { userContext } = useContext(UserContext);
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState(props.currentUser || "");
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(true);

  const history = useHistory();

  function createEditResponse() {
    return {
      username: props.username,
      login: user,
      password: pwd,
      //   email: null,
    };
  }

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd, matchPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);

    if (!v1 || !v2) {
      setSuccess(false);
      setErrMsg("Invalid Entry");
      return;
    }

    try {
      const response = await authFetch(getV1Path("/authentication/user"), {
        method: "PATCH",
        body: JSON.stringify(createEditResponse()),
        headers: {
          "Content-type": "application/json",
        },
      });
      if (response.status !== 200) {
        const message = await response.json().then((data) => data.message);
        setSuccess(false);
        setErrMsg(message);
        // InfoModal(message, `Error status code ${response.status}`)
        return;
      }

      console.log("trigger register");

      setSuccess(true);
      userContext.login = user;
      console.log(success);
      setUser("");
      setPwd("");
      setMatchPwd("");

      // InfoModal("Success", "User modified successfully");
      history.push("mainpage");
      // return <Redirect to="/home/preview" />
    } catch (err) {
      setSuccess(false);
      setErrMsg(`Unhandled exception ${err}`);
      // InfoModal("User modification failed", `Unhandled expeception ${err}`)
      errRef.current.focus();
    }
  };
  return (
    <>
      <div className="login-flow-reset">
        <div className="div">
          <div className="stacked-logos">
            <img className="boolean-group" src="img/boolean-group.svg" />
            <img className="logos-symbol-large" src="img/large.png" />
          </div>

          <button
            className="button-label-wrapper"
            disabled={!validName || !validPwd || !validMatch}
            onClick={handleSubmit}
          >
            <div className="text-wrapper">Bekräfta nya uppgifter</div>
          </button>

          <div className="LogoutButton">
            <LogoutButton />
          </div>
          {/* <button class="button-label-wrapper"
                    onClick={onLoginClick}
                    ><div class="text-wrapper">Login</div></button> */}

          <div className="overlap-group">
            <div className="group">
              <div className="text-input-field">
                <div className="label">Nytt användarnamn</div>
                <input
                  className="frame"
                  type="text"
                  id="username"
                  ref={userRef}
                  autoComplete="off"
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                  required
                  aria-invalid={validName ? "false" : "true"}
                  aria-describedby="uidnote"
                  onFocus={() => setUserFocus(true)}
                  onBlur={() => setUserFocus(false)}
                />
                <p
                  id="uidnote"
                  className={
                    userFocus && user && !validName
                      ? "instructions"
                      : "offscreen"
                  }
                >
                  4 to 24 characters.
                  <br />
                  Login have to start with letter.
                  <br />
                  Letters, numbers, underscores and dashes allowed.
                </p>
              </div>
            </div>
            <div className="text-input-field-wrapper">
              <div className="text-input-field">
                <div className="label-2">Nytt lösenord</div>
                <input
                  className="frame"
                  type="password"
                  id="password"
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  required={false}
                  aria-invalid={validPwd ? "false" : "true"}
                  aria-describedby="pwdnote"
                  onFocus={() => setPwdFocus(true)}
                  onBlur={() => setPwdFocus(false)}
                />
                <p
                  id="pwdnote"
                  className={
                    pwdFocus && !validPwd ? "instructions" : "offscreen"
                  }
                >
                  8 to 24 characters.
                  <br />
                  Have to include capital letter, lower case letter, number oraz
                  special character. Allowed special characters:{" "}
                  <span aria-label="exclamation mark">!</span>{" "}
                  <span aria-label="at symbol">@</span>{" "}
                  <span aria-label="hashtag">#</span>{" "}
                  <span aria-label="dollar sign">$</span>{" "}
                  <span aria-label="percent">%</span>
                </p>
              </div>
            </div>
            <div className="div-wrapper">
              <div className="text-input-field">
                <div className="label-2">Bekräfta lösenord</div>
                <input
                  className="frame"
                  type="password"
                  id="confirm_pwd"
                  onChange={(e) => setMatchPwd(e.target.value)}
                  value={matchPwd}
                  required={false}
                  aria-invalid={validMatch ? "false" : "true"}
                  aria-describedby="confirmnote"
                  onFocus={() => setMatchFocus(true)}
                  onBlur={() => setMatchFocus(false)}
                />
                <p
                  id="confirmnote"
                  className={
                    matchFocus && !validMatch ? "instructions" : "offscreen"
                  }
                >
                  Value in fields have to be the same.
                </p>
              </div>
            </div>
          </div>
          <div className="text-wrapper-2">Ändra inloggingsapgifter</div>
          {!success ? (
            <InfoModal
              header="Det gick inte att ändra användarnamn och lösenord: "
              text={errMsg}
            ></InfoModal>
          ) : null}
          <div className="text-wrapper-3">SAVER - Pilot</div>
        </div>
      </div>
    </>
  );
};

export default ChangeUserData;
