import React, { useState, useContext } from "react";
import { ApiContext } from "../Context/ApiContext";
import { UserContext } from "../Context/UserContext";
import { authFetch } from "../Authentication/AuthenticationProvider";

const DropDownSpotPrice = ({
  setSelectedOption,
  selectedOption,
  options,
  setErrorText,
  setErrorTextHide,
  setErrorTextHeader,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { getV1Path } = useContext(ApiContext);
  const { userContext } = useContext(UserContext);

  const onChangeClick = async (option) => {
    // e.preventDefault()

    authFetch(getV1Path("/authentication/user"), {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: userContext.username,
        spotPrice: option.value,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          userContext.spotPrice = option.value;
        } else {
          response.json().then((data) => {
            setErrorTextHeader("Operation Failed");
            setErrorText(
              "Spot price area can not be set due to: " + data.message
            );
            setErrorTextHide(false);
          });
        }
      })

      .catch((error) => {
        console.log(error);
        setErrorTextHeader("Operation Failed");
        setErrorText("No connection to server due to: " + error);
        setErrorTextHide(false);
      });
  };

  return (
    <div className={isOpen ? "dropdown-spotprice-with" : "dropdown-spotprice"}>
      <div className="skeleton" onClick={() => setIsOpen(!isOpen)}>
        <div className="select-option">
          {isOpen
            ? "Välj per."
            : selectedOption
            ? selectedOption.label
            : options[2].label}
        </div>
        <img
          className={isOpen ? "icons-navigation-up" : "icons-symbol-arrow"}
          src={isOpen ? "img/up.svg" : "img/down.svg"}
        />
      </div>
      {isOpen && (
        <div>
          {options.map((option) => (
            <div
              key={option.value}
              className={`link ${
                selectedOption.value === option.value ? "selected" : ""
              }`}
            >
              <div
                className={`winter-spring-summer ${
                  selectedOption.value === option.value ? "selected" : ""
                }`}
                onClick={(e) => {
                  setSelectedOption(option);
                  setIsOpen(false);
                  onChangeClick(option);
                }}
              >
                {option.label}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDownSpotPrice;
