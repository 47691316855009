import React, { useState, useEffect, useContext } from "react";
import GaugeMini from "../../Common/GaugeMini";
import Toggle from "../Toggle";
import GenericDropDown from "../GenericDropDown";
import CustomDatePicker from "../../Common/CustomDatePicker";
import CustomWeekPicker from "../../Common/CustomWeekPicker";
import { UserContext } from "../../Context/UserContext";
import { ApiContext } from "../../Context/ApiContext";
import {
  daysAfterDate,
  daysBeforeDate,
} from "../../Helpers/FunctionTimeHelpers";
import { authFetch } from "../../Authentication/AuthenticationProvider";
import { extractSensorID } from "../../Helpers/SensorTransformationHelpers";
import HistoryDataGraph from "../../Common/HistoryDataGraph";

const HistoryPage = () => {
  const { userContext } = useContext(UserContext);
  const { getV1Path } = useContext(ApiContext);
  const [activeTab, setActiveTab] = useState("day");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedWeek, setSelectedWeek] = useState(new Date());
  const [historicalData, setHistoricalData] = useState(null);
  const [displayCost, setDisplayCost] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const optionsHours = [
    { value: 0, label: "00:00-06:00" },
    { value: 6, label: "06:00-12:00" },
    { value: 12, label: "12:00-18:00" },
    { value: 18, label: "18:00-00:00" },
  ];

  const [selectedOptionHours, setSelectedOptionHours] = useState(
    optionsHours[0]
  );

  function isSelectedDate(date, selectedDate) {
    return (
      new Date(date + "Z").setHours(0, 0, 0, 0) ===
      new Date(selectedDate).setHours(0, 0, 0, 0)
    );
  }

  function wholeDayConsumption(data) {
    const selectedDateStart = new Date(selectedDate).setHours(0, 0, 0, 0);
    const selectedDateStop = new Date(selectedDate).setHours(23, 59, 59, 0);

    const arrayToSum = [];

    Object.keys(data).forEach((day) => {
      if (data[day].consumption) {
        data[day].consumption.x.forEach((timestamp, index) => {
          if (
            selectedDateStart <= new Date(timestamp + "Z") &&
            selectedDateStop >= new Date(timestamp + "Z")
          ) {
            arrayToSum.push(data[day].consumption.y[index]);
          }
        });
      }
    });
    const sumFromArray = arrayToSum.reduce((a, b) => a + b, 0);
    return Math.round(sumFromArray * 100) / 100;
  }

  function isInSelectedTimeRange(date, selectedDate, hourResolution) {
    const selectedDateStart = new Date(selectedDate).setHours(
      hourResolution,
      0,
      0,
      0
    );
    const selectedDateStop = new Date(selectedDate).setHours(
      hourResolution + 6,
      0,
      0,
      0
    );
    const checkingDate = new Date(date + "Z");
    return selectedDateStart <= checkingDate && selectedDateStop > checkingDate;
  }

  function displayGaugeValue() {
    return isDaySelected()
      ? wholeDayConsumption(historicalData)
      : getTotalForGraph("consumption", false);
  }

  function selectTimeRange(shouldConsumption, isWeek, data) {
    const consumptionGraph = { x: [], y: [] };
    const costsGraph = { x: [], y: [] };
    if (!isWeek) {
      Object.keys(data).forEach((day) => {
        let newConsumptionY = 0;
        const newConsumptionX = new Date(day).getDay();
        let newCostY = 0;
        const newCostX = new Date(day).getDay();
        if (data[day].consumption && data[day].costs) {
          data[day].consumption.x.forEach((timestamp, index) => {
            if (isSelectedDate(timestamp, day)) {
              newConsumptionY += data[day].consumption.y[index];
            }
          });

          data[day].costs.x.forEach((timestamp, index) => {
            if (isSelectedDate(timestamp, day)) {
              newCostY += data[day].costs.y[index];
            }
          });
        }
        consumptionGraph.y.push(newConsumptionY);
        consumptionGraph.x.push(newConsumptionX);
        costsGraph.x.push(newCostX);
        costsGraph.y.push(newCostY / 100);
      });

      return shouldConsumption
        ? { consumption: consumptionGraph, costs: costsGraph }
        : { consumption: consumptionGraph };
    }

    Object.keys(data).forEach((day) => {
      if (data[day].consumption && data[day].costs) {
        data[day].consumption.x.forEach((timestamp, index) => {
          if (
            isInSelectedTimeRange(
              timestamp,
              new Date(day),
              selectedOptionHours.value
            )
          ) {
            consumptionGraph.x.push(timestamp);
            consumptionGraph.y.push(data[day].consumption.y[index]);
          }
        });

        data[day].costs.x.forEach((timestamp, index) => {
          if (
            isInSelectedTimeRange(
              timestamp,
              new Date(day),
              selectedOptionHours.value
            )
          ) {
            costsGraph.x.push(timestamp);
            costsGraph.y.push(data[day].costs.y[index] / 100);
          }
        });
      }
    });

    return shouldConsumption
      ? { consumption: consumptionGraph, costs: costsGraph }
      : { consumption: consumptionGraph };
  }

  function isDaySelected() {
    return activeTab === "day";
  }

  function getDateArrayFromSelectedDate() {
    if (!isDaySelected()) {
      return Array(7)
        .fill(new Date(selectedWeek))
        .map(
          (el, idx) => new Date(el.setDate(el.getDate() - el.getDay() + idx))
        );
    }
    return [new Date(selectedDate)];
  }

  // TODO: Remove function isCost as the same logic is applied
  function getTotalForGraph(data, isCost) {
    const allData = isDaySelected()
      ? selectTimeRange(isCost, true, historicalData)
      : selectTimeRange(isCost, false, historicalData);
    const sumFromArray = allData[data].y.reduce((a, b) => a + b, 0);
    if (isCost) {
      return Math.round(sumFromArray * 100) / 100;
    }
    return Math.round(sumFromArray * 100) / 100;
  }

  useEffect(() => {
    const url = getV1Path(`/historicalData`);
    const days = getDateArrayFromSelectedDate();

    async function graphDataRequest(url, days, spotPrice) {
      setIsLoading(true);
      const requests = days.map((day) =>
        authFetch(url, {
          method: "POST",
          mode: "cors",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            from_date: daysBeforeDate(1, new Date(day).setHours(0, 0, 0, 0)),
            to_date: daysAfterDate(1, new Date(day).setHours(0, 0, 0, 0)),
            price_area: spotPrice,
            sensorConsumption: extractSensorID(userContext.AEI),
            sensorProduction: extractSensorID(userContext.AEE),
          }),
        })
      );
      const responses = await Promise.all(requests);
      const promises = responses.map((response) =>
        response.status === 200 ? response.json() : {}
      );
      const data = await Promise.all(promises);

      const newData = Object.fromEntries(
        data.map((response, index) => [days[index].toISOString(), response])
      );

      setHistoricalData(newData);
      setIsLoading(false);
    }

    if (!days && days.length === 0) {
      return null;
    }

    graphDataRequest(url, days, userContext.spotPrice);
  }, [activeTab, selectedDate, selectedWeek]);

  if (historicalData === null) {
    return null;
  }

  return (
    <>
      <div className="historical-view-day">
        <div className="div">
          <div className="molecules-navigation">
            <div className="overlap">
              <div className="group">
                <div className="overlap-group-wrapper">
                  <div className="overlap-group">
                    <img className="alarm" src="img/alarm.svg" />
                    <div className="frame"></div>
                  </div>
                </div>
              </div>
              <div className="molecules-navigation">
                <div className="frame-wrapper">
                  <div className="frame-4">
                    <div className="title">Historik</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overlap-2">
            <HistoryDataGraph
              graphData={
                isDaySelected()
                  ? selectTimeRange(displayCost, true, historicalData)
                  : selectTimeRange(displayCost, false, historicalData)
              }
              isDay={isDaySelected()}
              isCost={displayCost}
              isLoading={isLoading}
            />
          </div>
          <div className="frame-7">
            <div className="div-2">
              <div className="div-2">
                <GaugeMini
                  className="ellipse-2"
                  value={displayGaugeValue()}
                  max={displayGaugeValue()}
                  color={displayGaugeValue() >= 0 ? "#2071B5" : "#8FB8DA"}
                  svgSize="120"
                />
              </div>
            </div>
            <div className="frame-8">
              <div className="title-2">Total förbrukning/överskott i kWh</div>
              <div className="element">
                {displayGaugeValue() || "N/A"} kWh,&nbsp;&nbsp;{" "}
                {isDaySelected() ? "Idag" : "denna vecka"}
              </div>
            </div>
          </div>
          <div className="pill-tab-icon">
            <div className="frame-9">
              <div className="euro-wrapper">
                <img className="img-2" src="img/euro.svg" />
              </div>
              <div className="flexcontainer">
                <p className="text">
                  <span className="span">
                    Total kostnad <br />
                  </span>
                </p>
                <p className="text">
                  <span className="span">
                    {isDaySelected() ? "(vald dag)" : "(vald vecka)"}
                  </span>
                </p>
              </div>
            </div>
            <p className="label">
              <span className="text-wrapper-6">
                {" "}
                {getTotalForGraph("costs", true)} SEK{" "}
              </span>{" "}
              <span className="text-wrapper-7">(Exkl. avgift)</span>
            </p>
          </div>
          <div className="overlap-4">
            <div className="pill-tab-icon-2">
              <div className="frame-10">
                <div className="insight-graph-wrapper">
                  <img className="img-2" src="img/chart-3.svg" />
                </div>
                <div className="label-2">
                  Total förbrukning{" "}
                  {isDaySelected() ? "(vald dag)" : "(vald vecka)"}
                </div>
              </div>
              <div className="label-3">
                {getTotalForGraph("consumption", false)} kWh
              </div>
            </div>
          </div>
          <div className="tabs-container">
            <button
              className={`tab-wrapper ${activeTab === "day" ? "active" : ""}`}
              onClick={() => setActiveTab("day")}
            >
              <div className={`tab ${activeTab === "day" ? "active" : ""}`}>
                Dag
              </div>
            </button>
            <button
              className={`tab-wrapper ${activeTab === "week" ? "active" : ""}`}
              onClick={() => setActiveTab("week")}
            >
              <div className={`tab ${activeTab === "week" ? "active" : ""}`}>
                Vecka
              </div>
            </button>
          </div>
          {/* 
                    IF LEGEND NOT IN THE GRAPH, THEN HERE
                    <div className="graph-explanation">
                        <div className="div-3">
                            <div className="ellipse-2"></div>
                            <div className="text-wrapper-12">Energy usage consumed</div>
                        </div>
                        <div className="div-3">
                            <div className="ellipse-3"></div>
                            <p className="text-wrapper-12">Energy usage has been sold</p>
                        </div>
                        <div className="div-3">
                            <img className="line-2" src="img/line-51.svg" />
                            <p className="text-wrapper-12">Energy usage has been sold</p>
                        </div>
                    </div> */}
          <div className="frame-14">
            {activeTab === "day" ? (
              <CustomDatePicker
                startDate={selectedDate}
                setStartDate={setSelectedDate}
              />
            ) : (
              <CustomWeekPicker
                startWeek={selectedWeek}
                setStartWeek={setSelectedWeek}
              />
            )}
            {isDaySelected() ? (
              <GenericDropDown
                setSelectedOption={setSelectedOptionHours}
                selectedOption={selectedOptionHours}
                options={optionsHours}
              />
            ) : null}
          </div>

          <div className="frame-15">
            <Toggle onToggle={(value) => setDisplayCost(value)} />
            <div className="text-wrapper-13">Visa Kostnad</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryPage;
