import { useContext, useState, useEffect } from "react";
import {
  minus6H,
  getSpotPriceTimeZoneLabel,
  dayAfter,
} from "../Helpers/FunctionTimeHelpers";
import { UserContext } from "../Context/UserContext";
import { ApiContext } from "../Context/ApiContext";
import { authFetch } from "../Authentication/AuthenticationProvider";
import { extractSensorID } from "../Helpers/SensorTransformationHelpers";

const CurrentSpotPrice = () => {
  const [value, setValue] = useState(null);
  const { userContext } = useContext(UserContext);
  const { getV1Path } = useContext(ApiContext);

  function getSpotPriceValue(data) {
    if (data.spot_price && data.spot_price.x.length > 0) {
      for (const [index, timestamp] of data.spot_price.x.entries()) {
        if (getSpotPriceTimeZoneLabel(timestamp, "Europe/Stockholm", "sv-SV")) {
          setValue(data.spot_price.y[index]);
          break;
        }
      }
    }
  }

  useEffect(() => {
    async function sensorRequest(spotPrice) {
      const url = getV1Path(`/spotPrice`);

      await authFetch(url, {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          from_date: minus6H(),
          to_date: dayAfter(),
          price_area: spotPrice,
          sensorID: extractSensorID(userContext.AEI),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          getSpotPriceValue(data);
        });
    }

    sensorRequest(userContext.spotPrice);

    const timer = setInterval(async () => {
      sensorRequest(userContext.spotPrice);
    }, 1000 * 10 * 10);
    return () => clearInterval(timer);
  }, []);

  console.log(value);
  if (value === null) {
    return null;
  }

  return value;
};

export default CurrentSpotPrice;
