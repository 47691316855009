import { useContext, useState, useEffect } from "react";
import { minus6H, dayAfter } from "../Helpers/FunctionTimeHelpers";
import { UserContext } from "../Context/UserContext";
import { ApiContext } from "../Context/ApiContext";
import { authFetch } from "../Authentication/AuthenticationProvider";
import { extractSensorID } from "../Helpers/SensorTransformationHelpers";

const CurrentMeterStand = () => {
  const [value, setValue] = useState(null);
  const { userContext } = useContext(UserContext);
  const { getV1Path } = useContext(ApiContext);

  function getMeterStand(data) {
    if (data.sensorDataY && data.sensorDataY.length > 0) {
      setValue(parseInt(data.sensorDataY[0]));
      return;
    }
    setValue("N/A");
  }

  useEffect(() => {
    async function sensorRequest() {
      const url = getV1Path(
        `/getSensorData/${minus6H()}/${dayAfter()}/${extractSensorID(
          userContext.AEI
        )}`
      );

      await authFetch(url, {
        method: "GET",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.json())
        .then((data) => {
          getMeterStand(data);
        });
    }

    sensorRequest();

    const timer = setInterval(async () => {
      sensorRequest();
    }, 1000 * 10);
    return () => clearInterval(timer);
  }, []);

  if (value === null) {
    return null;
  }

  return value;
};

export default CurrentMeterStand;
