import React, { useState, useContext, useEffect } from "react";
import GaugeMini from "../../Common/GaugeMini";
import { UserContext } from "../../Context/UserContext";
import {
  dayBefore,
  threeDaysAfter,
  transofrmToHHMM,
  getSpotPriceTimeZoneLabel,
} from "../../Helpers/FunctionTimeHelpers";
import { ApiContext } from "../../Context/ApiContext";
import { authFetch } from "../../Authentication/AuthenticationProvider";
import {
  getMaxValueFromArray,
  getMinValueFromArray,
  extractSensorID,
} from "../../Helpers/SensorTransformationHelpers";
import SpotPriceGraph from "../../Common/SpotPriceGraph";

const SpotPricePage = () => {
  const { userContext } = useContext(UserContext);
  const { getV1Path } = useContext(ApiContext);
  const [activeTab, setActiveTab] = useState("today");
  const [currentValue, setCurrentValue] = useState(null);
  // const [maxValue, setMaxValue] = useState(null)
  // const [minValue, setMinValue] = useState(null)
  const [spotPriceToday, setSpotPriceToday] = useState(null);
  const [spotPriceTomorrow, setSpotPriceTomorrow] = useState(null);
  const [graphCurrentTimeLabel, setGraphCurrentTimeLabel] = useState(null);

  function getSpotPriceValue(data) {
    const graphLabel = { x1: [], x2: [] };
    if (data.spot_price && data.spot_price.x.length > 0) {
      for (const [index, timestamp] of data.spot_price.x.entries()) {
        if (getSpotPriceTimeZoneLabel(timestamp, "Europe/Stockholm", "sv-SV")) {
          setCurrentValue(data.spot_price.y[index]);
          graphLabel.x1.push(data.spot_price.x[index]);
          graphLabel.x2.push(data.spot_price.x[index + 1]);
          setGraphCurrentTimeLabel(graphLabel);
          break;
        }
      }
    }
  }

  function getTabMaxSpotPrice() {
    if (activeTab === "today") {
      return getMaxValueFromArray(spotPriceToday.y);
    }
    return getMaxValueFromArray(spotPriceTomorrow.y);
  }

  function getTabMaxSpotPriceTimestamp() {
    if (activeTab === "today") {
      const maxValue = getMaxValueFromArray(spotPriceToday.y);
      return transofrmToHHMM(
        spotPriceToday.x[spotPriceToday.y.indexOf(maxValue)]
      );
    }
    const maxValue = getMaxValueFromArray(spotPriceTomorrow.y);
    return transofrmToHHMM(
      spotPriceTomorrow.x[spotPriceTomorrow.y.indexOf(maxValue)]
    );
  }

  function getTabMinSpotPrice() {
    if (activeTab === "today") {
      return getMinValueFromArray(spotPriceToday.y);
    }
    return getMinValueFromArray(spotPriceTomorrow.y);
  }

  function getTabMinSpotPriceTimestamp() {
    if (activeTab === "today") {
      const minValue = getMinValueFromArray(spotPriceToday.y);
      return transofrmToHHMM(
        spotPriceToday.x[spotPriceToday.y.indexOf(minValue)]
      );
    }
    const minValue = getMinValueFromArray(spotPriceTomorrow.y);
    return transofrmToHHMM(
      spotPriceTomorrow.x[spotPriceTomorrow.y.indexOf(minValue)]
    );
  }

  function getspotPriceToday(data) {
    const todayData = { x: [], y: [] };

    const today = new Date();
    if (data.spot_price && data.spot_price.x.length > 0) {
      for (const [index, timestamp] of data.spot_price.x.entries()) {
        if (
          new Date(timestamp + "Z").setHours(0, 0, 0, 0) ===
          today.setHours(0, 0, 0, 0)
        ) {
          todayData.x.push(timestamp);
          todayData.y.push(data.spot_price.y[index]);
        }
      }
      setSpotPriceToday(todayData);
    }
  }

  function getspotPriceTomorrow(data) {
    const tomorrowData = { x: [], y: [] };
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (data.spot_price && data.spot_price.x.length > 0) {
      for (const [index, timestamp] of data.spot_price.x.entries()) {
        if (
          new Date(timestamp + "Z").setHours(0, 0, 0, 0) ===
          tomorrow.setHours(0, 0, 0, 0)
        ) {
          tomorrowData.x.push(timestamp);
          tomorrowData.y.push(data.spot_price.y[index]);
        }
      }
      setSpotPriceTomorrow(tomorrowData);
    }
  }

  useEffect(() => {
    async function spotPriceRequest(spotPrice) {
      const url = getV1Path(`/spotPrice`);

      await authFetch(url, {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          from_date: dayBefore(),
          to_date: threeDaysAfter(),
          price_area: spotPrice,
          sensorID: extractSensorID(userContext.AEI),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          getSpotPriceValue(data);
          getspotPriceTomorrow(data);
          getspotPriceToday(data);
        });
    }

    spotPriceRequest(userContext.spotPrice);

    const timer = setInterval(async () => {
      spotPriceRequest(userContext.spotPrice);
    }, 1000 * 10 * 10);
    return () => clearInterval(timer);
  }, []);

  if (
    currentValue === null ||
    spotPriceToday === null ||
    spotPriceTomorrow === null
  ) {
    return null;
  }

  return (
    <>
      <div className="spotprice-overview">
        <div className="div">
          <div className="molecules-navigation">
            <div className="overlap">
              <div className="group">
                <div className="overlap-group-wrapper">
                  <div className="overlap-group">
                    <img className="alarm" src="img/alarm-2.svg" />
                    <div className="frame"></div>
                  </div>
                </div>
              </div>
              <div className="molecules-navigation">
                <div className="frame-wrapper">
                  <div className="frame-4">
                    <div className="title">Spotpris</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="overlap-2">
            <div className="grouped-bar-line">
              <SpotPriceGraph
                graphData={
                  activeTab === "today" ? spotPriceToday : spotPriceTomorrow
                }
                graphCurrentTimeLabel={
                  activeTab === "today" ? graphCurrentTimeLabel : null
                }
              />
            </div>
          </div>

          <div className="frame-8">
            <div className="div-2">
              <div className="div-2">
                <GaugeMini
                  class="ellipse-2"
                  value={currentValue}
                  max={getMaxValueFromArray(spotPriceToday.y)}
                  color="#3DC07C"
                  svgSize="120"
                />
              </div>
            </div>
            <div className="frame-9">
              <p className="p">Timpris, just nu</p>
              <div className="element">{currentValue || "N/A"} öre/kWh</div>
            </div>
          </div>
          <div className="overlap-5">
            <div className="pill-tab-icon-3">
              <div className="frame-10">
                <img className="img-2" src="img/chart-3.svg" />
                <div className="label-5">Lägsta pris</div>
              </div>
              <div className="label-6">
                {getTabMinSpotPrice() || "N/A"} öre/kWh
              </div>
              <div className="label-5">
                {getTabMinSpotPriceTimestamp() || "N/A"}
              </div>
            </div>
            <div className="pill-tab-icon-4">
              <div className="frame-11">
                <img className="img-2" src="img/chart-3.svg" />
                <div className="label-7">Högsta pris</div>
              </div>
              <div className="label-6">
                {getTabMaxSpotPrice() || "N/A"} öre/kWh
              </div>
              <div className="label-7">
                {getTabMaxSpotPriceTimestamp() || "N/A"}
              </div>
            </div>
          </div>
          <div className="tabs-container">
            <button
              className={`tab-wrapper ${activeTab === "today" ? "active" : ""}`}
              onClick={() => setActiveTab("today")}
            >
              <div className={`tab ${activeTab === "today" ? "active" : ""}`}>
                Idag
              </div>
            </button>
            <button
              className={`tab-wrapper ${
                activeTab === "tomorrow" ? "active" : ""
              }`}
              onClick={() => setActiveTab("tomorrow")}
            >
              <div
                className={`tab ${activeTab === "tomorrow" ? "active" : ""}`}
              >
                Imorgon
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpotPricePage;
