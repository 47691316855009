import React from "react";
import { useHistory } from "react-router-dom";
import ExpandableText from "../ExpandableText";
import { useAuth } from "../../Authentication/AuthenticationProvider";

const Manual = () => {
  const [logged] = useAuth();
  const history = useHistory();
  const onCloseClick = (e) => {
    if (logged) {
      history.push("/home/myaccount");
    } else {
      history.push("/start");
    }
  };

  return (
    <div className="manual">
      <div className="div">
        <div className="overlap">
          <div className="frame">
            <div className="frame-2">
              <div className="text-wrapper">Användarmanual</div>
              <button className="close-button" onClick={onCloseClick}>
                <img className="navigation-close" src="img/close.svg" />
              </button>
            </div>
          </div>
          <p className="p">
            Här kan du konfigurera din SAVER-enhet till din elmätare, vänligen
            följ instruktionerna nedan.
          </p>
        </div>
        <div className="frame-3">
          <div className="frame-4">
            <div className="text-wrapper-3">Introduktion</div>
            <p className="text-wrapper-4">
              Att konfigurera SAVER-enheten kräver att du är uppkopplad på ditt
              hemmanätverk och befinner dig nära mätaren. Det kan underlätta att
              ha två mobiler vid konfigurationen. Då kan du på en enhet ha
              installationsmanualen tillgänglig och på den andra enheten
              konfigurera SAVER-enheten. Det går också bra att göra det på en
              enda enhet men det kommer att krävas att du växlar mellan olika
              fönster.
              <br />
              <br />
              Du konfigurerar SAVER-enheten på det lokala gränssnittet som dyker
              upp när du kopplar in den i elmätaren. Detta gränssnitt är bara
              tillgängligt när du är hemma och uppkopplad på samma nätverk som
              enheten. Du kommer alltid att ha tillgång till SAVER-gränssnittet
              för att se din förbrukning och vi rekommenderar att du använder
              detta under piloten. Det lokala gränssnittet används endast för
              konfigurationen.
            </p>
          </div>
          <ExpandableText
            title="Steg 1 - Anslut enheten"
            content={
              <>
                <p className="lorem-ipsum-dolor">
                  <br />
                  <span className="text-wrapper-4">
                    <ol>
                      <li>
                        Inaktivera mobildata på din mobiltelefon och var
                        uppkopplad på ditt hemmanätverk.
                      </li>
                      <br />
                      <li>
                        Koppla in SAVER-enheten i HAN/P1-porten på din mätare.
                      </li>
                      <br />
                      <li>
                        Efter några minuter ser du ett WiFi-nätverk som heter
                        &quot;P1_Setup&quot;, anslut till det (du kommer inte
                        att ha internetanslutning)
                      </li>
                      <br />
                      <li>
                        I adressen / URL i din webbläsare skriver du in
                        &quot;192.168.4.1&quot; och trycker på
                        &quot;enter/go&quot;.
                      </li>
                      <br />
                      <li>
                        Du kommer nu att se sida/menyn nedan (kanske annan
                        version).
                      </li>
                    </ol>
                    <br />
                  </span>
                </p>
                {/* <div className="picture">
                  <div className="rectangle"></div>
                </div> */}
                <div className="picture">
                  {/* <div className="rectangle"></div> */}
                  <img className="rectangle" src="img/Manual-1.png" />
                </div>
              </>
            }
          />
          <ExpandableText
            title="Steg 2 - Anslut till Wi-Fi"
            content={
              <>
                <p className="lorem-ipsum-dolor">
                  <br />
                  <span className="text-wrapper-4">
                    <ol start="6">
                      <li>Klicka på &quot;Setup&quot;.</li>
                      <br />
                      <li>Du kommer att se vyn nedan.</li>
                      <br />
                      <div className="picture">
                        <img className="rectangle" src="img/Manual-2.png" />
                      </div>
                      <br />
                      <li>
                        I &quot;SSID&quot; ska du fylla i namnet på ditt Wi-Fi,
                        därefter ska du i ”Password” skriva in lösenordet till
                        ditt Wi-Fi.
                      </li>
                      <br />
                      <li>
                        Fyll i storleken på din huvudsäkring i rutan under ”Fuse
                        Size (A)”. Detta krävs för att du ska kunna ta del av en
                        funktion som varnar dig för risk för överbelastning av
                        säkring.
                      </li>
                      <br />
                      <li>
                        Ställ in din önskade maxgräns för din effekt i rutan
                        under ”Notify at power (kW)”. Detta krävs om du önskar
                        att få en varning när du passerar denna gräns.
                      </li>
                      <br />
                      <li>
                        För att delta i piloten behöver Vattenfall få
                        godkännande om att hantera och lagra din data. Genom att
                        klicka i rutan ”Send to ITEM cloud” ger du ditt samtycke
                        till att Vattenfall lagar din data.
                      </li>
                      <br />
                      <li>Klicka sedan på knappen ”Save”</li>
                      <br />
                      <li>
                        Enheten startar nu om och du kommer att se fönstret
                        nedan på skärmen. Låt det gå några sekunder.
                      </li>
                      <br />
                      <div className="picture">
                        {/* <div className="rectangle"></div> */}
                        <img className="rectangle" src="img/Manual-3.png" />
                      </div>
                      <br />
                      <li>
                        Om konfigurationen har gått igenom kommer nätverket
                        &quot;P1_Setup&quot; inte längre att vara synligt i dina
                        Wi-Fi-inställningar. Det kan ta någon minut innan data
                        börjar skickas.
                        <br />
                        Gå in och verifiera att det har utförts korrekt genom
                        att i adressen/URL i din webbläsare skriva:
                        ”vf-dongle.local”. Då kommer du att komma tillbaka till
                        sidan nedan:
                      </li>
                      <br />
                      <div className="picture">
                        <img
                          className="rectangle-manual4"
                          src="img/Manual-4.png"
                        />
                      </div>
                      <br />
                      <li>
                        Skriv ner ditt MAC-ID som är det nummer inringat i
                        bilden ovan. Vänligen skicka det i ett mail till
                        alma.klipic@vattenfall.com
                      </li>
                      <br />
                      <li>
                        Klicka in dig på ”Meterdata” eller ”Graph” för att se
                        att det skickas värden enligt bilden nedan.
                      </li>
                      <br />
                      <div className="picture">
                        <div className="rectangle-manual56">
                          <img
                            className="rectangle-manual5"
                            src="img/Manual-5.png"
                          />
                          <img
                            className="rectangle-manual5"
                            src="img/Manual-6.png"
                          />
                        </div>
                      </div>
                    </ol>
                    <br />
                    <br />
                  </span>
                </p>
              </>
            }
          />
          <ExpandableText
            title="Steg 3 - Logga in på SAVER-gränssnittet"
            content={
              <>
                <p className="lorem-ipsum-dolor">
                  <br />
                  <span className="text-wrapper-4">
                    <ol start="17">
                      <li>
                        När konfigurationen till ditt hemmanätverk är klart kan
                        du gå tillbaka till SAVER-gränssnittet:
                        saverpilot.vattenfall.com. Detta gränssnitt går att
                        använda oavsett om du är hemma eller inte. Du behöver
                        inte vara uppkopplad på ditt Wi-Fi-nätverk.
                      </li>
                      <br />
                      <li>
                        <br />
                        För Safari: Klicka på knappen ”Dela” i menyfältet längst
                        ner. Välj ”Lägg till på hemskärm” för att skapa en
                        app-ikon på din mobil som du enkel har tillgång till.
                        <br />
                        <br />
                        För Chrome: Klicka på de tre prickarna uppe till höger.
                        Välj ”Lägg till på hemskärm” för att skapa en app-ikon
                        på din mobil som du enkel har tillgång till.
                      </li>
                      <br />
                      <li>
                        Vid första inloggning i SAVER-gränssnittet, loggar du in
                        med ditt MAC-ID (utan kolon) som du använder till både
                        användarnamn och lösenord.
                        <br />
                        Du kommer alltid att ha möjligheten att logga in med
                        MAC-ID som användarnamn. Det kan dock vara svårt att
                        memorera detta. Därmed ges möjligheten att ställa in
                        sitt egna användarnamn och lösenord.
                      </li>
                      <br />
                      <li>
                        Efter lyckad inloggning med MAC-ID kommer du att bli
                        ombedd att välja ett nytt användarnamn och lösenord,
                        vänligen gör det.
                      </li>
                      <br />
                      <li>
                        Du kommer att få en varning om användarnamnet som du
                        väljer redan existerar. Då får du välja ett nytt.
                      </li>
                      <br />
                      <li>
                        Om du väljer ett unikt användarnamn och ett lösenord som
                        uppfyller kriterierna kommer du att logga in och tas
                        till förstasidan.
                      </li>
                      <br />
                      <li>
                        <br />
                        För Safari: Klicka på knappen ”Dela” i menyfältet längst
                        ner. Välj ”Lägg till på hemskärm” för att skapa en
                        app-ikon på din mobil som du enkel har tillgång till.
                        <br />
                        <br />
                        För Chrome: Klicka på de tre prickarna uppe till höger
                        och ”Lägg till på hemskärm” för att skapa en app-ikon på
                        din mobil som du enkel har tillgång till.
                      </li>
                      <br />
                      <li>
                        Projektet rekommenderar att du aktiverar autolås på din
                        mobil för att undvika att obehöriga får tillgång till
                        din information. Du kan ställa in detta i dina
                        mobilinställningar. Genom att ha autolås aktiverat
                        kommer din mobil automatiskt låsas när den inte används,
                        vilket ökar säkerheten och minskar risken för obehörig
                        åtkomst till din information.
                      </li>
                      <br />
                      <li>
                        Projektet rekommenderar att du ändrar admin-lösenordet
                        till din router från fabriksinställda lösenordet.
                      </li>
                    </ol>
                    <br />
                    <br />
                  </span>
                </p>
              </>
            }
          />
          <ExpandableText
            title="SAVER-gränssnittet"
            content={
              <>
                <p className="lorem-ipsum-dolor">
                  <br />
                  <span className="text-wrapper-4">
                    <strong>Förstasidan</strong> – Här kan du välja att logga in
                    eller att gå till användarmanualen.
                    <br />
                    <br />
                    <strong>Logga in</strong> – Här skriver du in ditt
                    användarnamn och lösenord. Om du har glömt ditt lösenord kan
                    du återställa det genom att klicka på “Återställ
                    inloggningsuppgifter”.
                    <br />
                    <br />
                    <strong>Återställ inloggningsuppgifter</strong> – Skriv in
                    ditt MAC-ID. Du kommer att återställa allt så att du loggar
                    in med MAC-ID som användarnamn och lösenord. Du kommer att
                    behöva välja ett nytt användarnamn och lösenord efter
                    återställningen.
                    <br />
                    <br />
                    <strong>Översikt</strong> – Förstasidan vid inloggning. Här
                    får du en överblick över din effekt i realtid, aktuellt
                    spotpris och gårdagens förbrukning. Klickar du på ”se graf”
                    i blått för de tre olika alternativen kommer du att
                    navigeras till det specifika fönstret i menyfältet.
                    <br />
                    <br />
                    <strong>Realtid</strong> – Levande graf som uppdateras i
                    realtid. Det går att välja mellan att se grafen med olika
                    tidsspann, per fas samt ändra från effekt till spänning
                    eller ström.
                    <br />
                    <br />
                    <strong>Spotpris</strong> – Aktuellt elpris för innevarande
                    dag samt morgondagen.
                    <br />
                    <br />
                    <strong>Historik</strong> – Graf som visar tidigare dagars
                    förbrukning med 15-minuters upplösning. Du kan välja att
                    titta per dag eller per vecka. Du kan även se kostnaden för
                    din förbrukning.
                    <br />
                    <br />
                    <strong>Mitt konto</strong> – Under ”Mitt konto” kan du
                    ändra inställningarna för max effekt, max ström, max totala
                    förbrukning/dag samt elprisområde. Du hittar även manualen
                    och frågor & svar samt utloggningsknappen i denna vy.
                    <br />
                    <br />
                    <strong>Ge feedback</strong> – Denna knapp kommer alltid att
                    vara synlig och vi uppmanar dig att lämna all form av
                    feedback för att vi ska kunna förbättra produkten.
                    <br />
                    <br />
                    <strong>Rapportera ett problem</strong> – Om det uppstår
                    något fel som du behöver rapportera under testet gör du det
                    genom att klicka på knappen ”Rapportera ett problem” som
                    finns tillgänglig i samtliga fönster i SAVER-gränssnittet.
                    Ditt ärende kommer att skickas till berörda i projektet som
                    kommer att ta kontakt med dig och hjälpa dig med ditt
                    problem.
                    <br />
                    <br />
                    <strong>Notiser</strong> – Denna symbol är tillgänglig i
                    alla fönster där det går att hitta alla notiser som uppstår
                    under testperioden. Här kommer det att dyka upp enkäter som
                    det förväntas från dig som deltagare i SAVER-piloten att
                    svara på. Genom att klicka på denna notis kommer du att
                    dirigeras till en annan sida där du kan svara på enkäten.
                    <br />
                    <br />
                  </span>
                </p>
              </>
            }
          />
          <div className="frame-7"></div>
          <div className="frame-8"></div>
          <div className="frame-5">
            <div className="text-wrapper-3">Frågor och svar</div>
            <ExpandableText
              title="Kan jag vara med och delta i piloten och använda SAVER-enheten utan att vara Vattenfall-kund?  "
              content={
                <p className="text-wrapper-4">
                  Ja, du behöver inte vara kund hos Vattenfall för att delta i
                  piloten och använda SAVER-enheten.
                </p>
              }
            />
            <ExpandableText
              title="Min elmätare sitter utomhus, kommer jag att få täckning dit?"
              content={
                <p className="text-wrapper-4">
                  Det beror på hur långt din mätare sitter från din
                  Wi-Fi-router. Innan du börjar att konfigurera enheten kan du
                  gå ut till mätaren och undersöka din täckning.
                </p>
              }
            />
            <ExpandableText
              title="Kan mätaren strömförsörja SAVER-enheten?"
              content={
                <p className="text-wrapper-4">
                  Alla mätartillverkare kan inte strömförsörja SAVER-enheten. Om
                  du har någon av följande mätartillverkare krävs ingen extern
                  strömförsörjning: Sanxing, Sagemcom, Landis+Gyr.
                </p>
              }
            />
            <ExpandableText
              title="Om jag kan strömförsörja SAVER-enheten, fungerar det då på alla mätare?"
              content={
                <p className="text-wrapper-4">
                  Nej, i dagsläget fungerar SAVER-enheten endast på Sanxing,
                  Sagemcom och Landis+gyr.
                </p>
              }
            />
            <ExpandableText
              title="Jag kan inte se min data, vad är fel?"
              content={
                <p className="text-wrapper-4">
                  Börja med att kontrollera att ditt Wi-Fi verkligen fungerar
                  som det ska. Om du inte har problem med ditt Wi-Fi, vänligen
                  rapportera problemet i rapporteringsfunktionen.
                </p>
              }
            />
            <ExpandableText
              title="Jag får inte till konfigurationen av SAVER-enheten, vad är fel?"
              content={
                <p className="text-wrapper-4">
                  Kontrollera att du skriver rätt WiFi-namn och lösenord. Om du
                  har skrivit fel ska du dra ut SAVER-enheten och vänta en stund
                  innan du kopplar in den i mätaren igen. Då ska ”P1_Setup” dyka
                  upp igen och du kan börja med konfigurationen på nytt.
                  <br />
                  Vi har även märkt att vissa mätares HAN-port glappar när vår
                  SAVER-enhet är ihopkopplad. Testa att hålla i och trycka in
                  enheten medan du konfigurerar den. Fungerar det medan du
                  håller in enheten och inte när du släpper den är det mest
                  troligt ett glapp. För att åtgärda detta kan man fästa enheten
                  med hjälp av olika metoder. Vänligen kontakta:
                  alma.klipic@vattenfall.com för vidare support.
                </p>
              }
            />
            <ExpandableText
              title="Fungerar SAVER-enheten i alla klimat?"
              content={
                <p className="text-wrapper-4">
                  Då det är en pilot och en egenutvecklad produkt kan vi inte
                  garantera att SAVER-enheten fungerar i mer ansträngt klimat.
                </p>
              }
            />
            <ExpandableText
              title="Varför har visualiseringen för realtid gjorts i ett separat gränssnitt och inte i ”Mitt Vattenfall”-appen?"
              content={
                <p className="text-wrapper-4">
                  Då det är en pilot för att testa denna typ av produkt har vi
                  valt att göra det i en testmiljö. En framtida kommersiell
                  produkt kommer att visualiseras i ”Mitt Vattenfall”.
                </p>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Manual;
