import { Route, useHistory } from "react-router-dom";
import Home from "./Home";
import React from "react";

const HomeRouter = () => {
  const history = useHistory();
  return (
    <>
      {/* <Route path="/home/preview">
        <Home screen="preview" />
      </Route>
      <Route path="/home/graphs">
        <Home screen="graphs" />
      </Route> */}
      <Route path="/home/userData">
        <Home screen="modifyUserData" />
      </Route>
      <Route path="/home/spotprice">
        <Home screen="spotprice" />
      </Route>
      <Route path="/home/realtime">
        <Home screen="realtime" />
      </Route>
      <Route path="/home/mainpage">
        <Home screen="mainpage" />
      </Route>
      <Route path="/home/history">
        <Home screen="history" />
      </Route>
      <Route path="/home/myaccount">
        <Home screen="myAccount" />
      </Route>
      <Route
        path="/refresh"
        exact
        component={() => {
          console.log("goBack");
          history.goBack();

          return <> </>;
        }}
      />
    </>
  );
};
export default HomeRouter;
