import React from "react";
import { useHistory } from "react-router-dom";

const ModalButton = ({ path, text }) => {
  const history = useHistory();
  return (
    <div className="official-button">
      <button className="button" onClick={() => history.push(path)}>
        <div className="spacing">
          <div className="label-3">{text}</div>
        </div>
      </button>
    </div>
  );
};

export default ModalButton;
