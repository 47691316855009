import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = ({ startDate, setStartDate }) => {
  // const [startDate, setStartDate] = useState(new Date());
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="custom-date-picker" onClick={onClick} ref={ref}>
      {value}
      <img className={"calendar-icon"} src={"img/calendar-31.svg"} />
    </button>
  ));
  ExampleCustomInput.displayName = "ExampleCustomInput";

  return (
    <DatePicker
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      customInput={<ExampleCustomInput />}
      withPortal
      // showIcon
      // toggleCalendarOnIconClick
      // calendarIconClassname="calendar-icon"
      // icon={"img/calendar-31.svg"}
    />
  );
};

export default CustomDatePicker;
