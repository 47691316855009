import React, { useState, useContext, useEffect } from "react";
import GaugeMini from "../../Common/GaugeMini";
import DropDownInput from "../DropdownInput";
import { UserContext } from "../../Context/UserContext";
import { authFetch } from "../../Authentication/AuthenticationProvider";
import {
  extractSensorID,
  getMaxValueFromArray,
} from "../../Helpers/SensorTransformationHelpers";
import {
  minus10Min,
  minus1H,
  minus6H,
  currentTime,
  dayBefore,
} from "../../Helpers/FunctionTimeHelpers";
import { ApiContext } from "../../Context/ApiContext";
import CurrentSpotPrice from "../../Common/CurrentSpotPrice";
import RealTimeGraph from "../../Common/RealTimeGraph";
import CurrentMeterStand from "../../Common/CurrentMeterStand";

const graphLayout = {
  AP: {
    url: {
      TOTAL: "AEPD",
      L1: "IAIP1",
      L2: "IAIP2",
      L3: "IAIP3",
      TOTAL_EXPORT: "IAEP",
      EL1: "IAEP1",
      EL2: "IAEP2",
      EL3: "IAEP3",
    },
    colors: { TOTAL: "s", L1: "S", L2: "S", L3: "S" },
    layout: {
      title: "Aktuell effekt",
      unit: "W",
      parameter: "effekt",
    },
    transformationFactor: 1000,
  },
  IV: {
    url: { L1: "IV1", L2: "IV2", L3: "IV3" },
    colors: { L1: "S", L2: "S", L3: "S" },
    layout: {
      title: "Aktuell spänning",
      unit: "V",
      parameter: "spänning",
    },
    transformationFactor: 1,
  },
  IC: {
    url: { L1: "IC1", L2: "IC2", L3: "IC3" },
    colors: { L1: "S", L2: "S", L3: "S" },
    layout: {
      title: "Aktuell ström",
      unit: "A",
      parameter: "ström",
    },
    transformationFactor: 1,
  },
};

const options = [
  { value: "AP", label: "Effekt (W)" },
  { value: "IC", label: "Ström (A)" },
  { value: "IV", label: "Spänning (V)" },
];

const RealTimePage = () => {
  const { userContext } = useContext(UserContext);
  const { getV1Path } = useContext(ApiContext);

  const [activeTimeFrame, setActiveTimeFrame] = useState("10min");
  const [activeTab, setActiveTab] = useState("TOTAL");
  const [L1Data, setL1Data] = useState({});
  const [L2Data, setL2Data] = useState({});
  const [L3Data, setL3Data] = useState({});
  const [totalData, setTotalData] = useState({});
  const [displayGraphData, setDisplayGraphData] = useState(["L1", "L2", "L3"]);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [boxL1, setBoxL1] = useState(true);
  const [boxL2, setBoxL2] = useState(true);
  const [boxL3, setBoxL3] = useState(true);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  function getOverviewData(data) {
    if (Object.keys(totalData).length !== 0) {
      return true;
    }
    return false;
  }

  // async function fetchSingleSensorData(url) {
  //   const startDate = assignStartDate(activeTimeFrame);
  //   const endDate = currentTime();
  //   const response = await authFetch(
  //     getV1Path(
  //       `/getSensorData/${startDate}/${endDate}/${extractSensorID(
  //         userContext[url]
  //       )}`
  //     ),
  //     {
  //       method: "GET",
  //       mode: "cors",
  //       headers: { "Content-Type": "application/json" },
  //     }
  //   );
  //   if (response.status === 200) {
  //     const data = await response.json();
  //     const latestSensorValue = data[data.length - 1];
  //     return latestSensorValue;
  //   } else {
  //     return null;
  //   }
  // }

  function getLatestRead(data) {
    if (Object.keys(data).length !== 0) {
      return (
        data.sensorDataY[0] *
        graphLayout[selectedOption.value].transformationFactor
      ).toFixed(2);
    }
  }

  const setNewCheckBoxState = (event) => {
    console.log(event.target.checked);
    console.log(displayGraphData);
    if (event.target.checked) {
      const newArrayChecked = displayGraphData;
      newArrayChecked.push(event.target.name);
      setDisplayGraphData(newArrayChecked);
    } else {
      let newArray = [];
      newArray = displayGraphData.filter((e) => e !== event.target.name);
      setDisplayGraphData(newArray);
    }

    // if(!displayGraphData.includes(value)){
    //     // console.log(newArray)
    //     displayGraphData.push(value)
    //     // console.log(newArray)
    //     setDisplayGraphData(displayGraphData)
    // }
  };

  const dispatchTime = {
    "10min": function () {
      return minus10Min();
    },
    "1h": function () {
      return minus1H();
    },
    "6h": function () {
      return minus6H();
    },
    "24h": function () {
      return dayBefore();
    },
  };

  function assignStartDate(selectedTimeState) {
    return dispatchTime[selectedTimeState]();
  }

  function getOverlapData(data, exportIndex, index) {
    if (
      typeof data[exportIndex] !== "undefined" &&
      typeof (data[exportIndex].sensorDataX !== "undefined") &&
      Object.keys(data[exportIndex]).length !== 0
    ) {
      const newSensorDataX = [];
      const newSensorDataY = [];
      data[index].sensorDataX.forEach(function (value, i) {
        const relatedIndex = data[exportIndex].sensorDataX.indexOf(value);
        if (relatedIndex !== -1) {
          newSensorDataX.push(value);
          newSensorDataY.push(
            data[index].sensorDataY[i] -
              data[exportIndex].sensorDataY[relatedIndex]
          );
        }
      });
      data[index].sensorDataY = newSensorDataY;
      data[index].sensorDataX = newSensorDataX;
      delete data[exportIndex];
    }
  }

  function responseDataActivePowerTransformation(data) {
    if (selectedOption.value !== "AP") {
      console.log(data);
      return data;
    }
    console.log("DATA INITIAL");
    console.log(data);
    Object.keys(graphLayout[selectedOption.value].url).forEach((el, index) => {
      switch (el) {
        case "TOTAL":
          getOverlapData(data, 4, index);
          break;

        case "L1":
          getOverlapData(data, 5, index);
          break;

        case "L2":
          getOverlapData(data, 6, index);
          break;
        case "L3":
          getOverlapData(data, 7, index);
          break;
      }
    });
    console.log("AFTER DATA");
    console.log(data);
    return data;
  }

  function assignResponseResults(data, index, element) {
    switch (element) {
      case "L1":
        setL1Data(data[index]);
        break;
      case "L2":
        setL2Data(data[index]);
        break;
      case "L3":
        setL3Data(data[index]);
        break;
      case "TOTAL":
        setTotalData(data[index]);
        break;
      default:
        console.log(`Sorry, we are out of ${element}.`);
    }
  }

  useEffect(() => {
    const urls = Object.values(graphLayout[selectedOption.value].url);
    async function graphDataRequest(urls) {
      const endDate = currentTime();
      const startDate = assignStartDate(activeTimeFrame);

      const requests = urls.map((url) =>
        authFetch(
          getV1Path(
            `/getSensorData/${startDate}/${endDate}/${extractSensorID(
              userContext[url]
            )}`
          ),
          {
            method: "GET",
            mode: "cors",
            headers: { "Content-Type": "application/json" },
          }
        )
      );
      const responses = await Promise.all(requests);
      const promises = responses.map((response) =>
        response.status === 200 ? response.json() : {}
      );
      const data = await Promise.all(promises);
      const newData = data;
      console.log(newData);
      const transformedData = responseDataActivePowerTransformation(data);
      console.log(transformedData);
      Object.keys(graphLayout[selectedOption.value].url).forEach(
        (el, index) => {
          assignResponseResults(transformedData, index, el);
        }
      );
    }

    if (!urls && urls.length === 0) {
      return null;
    }

    graphDataRequest(urls);

    // sensorRequest(dayBefore(), currentTime(), sensor, spotPrice);
    const timer = setInterval(async () => {
      graphDataRequest(urls);
    }, 5000);
    return () => clearInterval(timer);
  }, [activeTab, activeTimeFrame, selectedOption]);

  if (userContext.AEPD === null) {
    return null;
  }

  function createGraphData() {
    const graphData = {};
    if (activeTab === "TOTAL") {
      if (
        Object.keys(graphLayout[selectedOption.value].url).includes("TOTAL")
      ) {
        graphData.TOTAL = totalData;
      } else {
        graphData.L1 = L1Data;
        graphData.L2 = L2Data;
        graphData.L3 = L3Data;
      }
      return graphData;
    }
    displayGraphData.forEach((element) => {
      if (
        Object.keys(graphLayout[selectedOption.value].url).includes(element)
      ) {
        if (element === "L1") {
          graphData.L1 = L1Data;
        }
        if (element === "L2") {
          graphData.L2 = L2Data;
        }
        if (element === "L3") {
          graphData.L3 = L3Data;
        }
      }
    });
    return graphData;
  }

  console.log(displayGraphData);
  return (
    <>
      <div className="actual-power-total">
        <div className="div">
          <div className="molecules-navigation">
            <div className="overlap">
              <div className="group">
                <div className="overlap-group-wrapper">
                  <div className="overlap-group">
                    <img className="alarm" src="img/alarm-2.svg" />
                    <div className="frame"></div>
                  </div>
                </div>
              </div>
              <div className="molecules-navigation">
                <div className="frame-wrapper">
                  <div className="frame-4">
                    <div className="title">Realtid</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overlap-2">
            {activeTab === "TOTAL" ? null : (
              <div className="container-checkbox">
                <div className="div-3">
                  <input
                    type="checkbox"
                    id="1"
                    name="L1"
                    checked={boxL1}
                    onChange={(e) => {
                      setNewCheckBoxState(e);
                      setBoxL1(!boxL1);
                    }}
                    disabled={displayGraphData.length === 1 && boxL1}
                    className="hidden-checkbox"
                  />
                  <label className="div-3" htmlFor="1">
                    <img
                      className="checkbox"
                      src={
                        boxL1 ? "img/checkbox-checked.svg" : "img/checkbox.svg"
                      }
                    />
                    <div className="div-2">
                      <div className="ellipse-4"></div>
                      <div className="text-wrapper">L1</div>
                    </div>
                  </label>
                </div>
                <div className="div-3">
                  <input
                    type="checkbox"
                    id="2"
                    name="L2"
                    checked={boxL2}
                    onChange={(e) => {
                      setNewCheckBoxState(e);
                      setBoxL2(!boxL2);
                    }}
                    disabled={displayGraphData.length === 1 && boxL2}
                    className="hidden-checkbox"
                  />
                  <label className="div-3" htmlFor="2">
                    <img
                      className="checkbox"
                      src={
                        boxL2 ? "img/checkbox-checked.svg" : "img/checkbox.svg"
                      }
                    />
                    <div className="div-2">
                      <div className="ellipse-2"></div>
                      <div className="text-wrapper">L2</div>
                    </div>
                  </label>
                </div>
                <div className="div-3">
                  <input
                    type="checkbox"
                    id="3"
                    name="L3"
                    checked={boxL3}
                    onChange={(e) => {
                      setNewCheckBoxState(e);
                      setBoxL3(!boxL3);
                    }}
                    disabled={displayGraphData.length === 1 && boxL3}
                    className="hidden-checkbox"
                  />
                  <label className="div-3" htmlFor="3">
                    <img
                      className="checkbox"
                      src={
                        boxL3 ? "img/checkbox-checked.svg" : "img/checkbox.svg"
                      }
                    />
                    <div className="div-2">
                      <div className="ellipse-3"></div>
                      <div className="text-wrapper">L3</div>
                    </div>
                  </label>
                </div>
              </div>
            )}
            <div className="grouped-bar-line">
              <RealTimeGraph
                graphData={createGraphData()}
                graphLayout={graphLayout[selectedOption.value].layout}
                transformationFactor={
                  graphLayout[selectedOption.value].transformationFactor
                }
              />
            </div>
          </div>
          <div className="frame-7">
            <button
              className={`pill-tab-icon ${
                activeTimeFrame === "10min" ? "active" : ""
              }`}
              onClick={() => {
                setActiveTimeFrame("10min");
              }}
            >
              <div className="label-3">10 min</div>
            </button>
            <button
              className={`pill-tab-icon ${
                activeTimeFrame === "1h" ? "active" : ""
              }`}
              onClick={() => {
                setActiveTimeFrame("1h");
              }}
            >
              <div className="label-4">1 t</div>
            </button>
            <button
              className={`pill-tab-icon ${
                activeTimeFrame === "6h" ? "active" : ""
              }`}
              onClick={() => {
                setActiveTimeFrame("6h");
              }}
            >
              <div className="label-4">6 t</div>
            </button>
            <button
              className={`pill-tab-icon ${
                activeTimeFrame === "24h" ? "active" : ""
              }`}
              onClick={() => {
                setActiveTimeFrame("24h");
              }}
            >
              <div className="label-4">24 t</div>
            </button>
          </div>
          <div className="frame-8">
            <div className="div-2">
              <div className="div-2">
                <GaugeMini
                  class="ellipse-2"
                  value={
                    getOverviewData()
                      ? getLatestRead(totalData)
                      : getLatestRead(L1Data)
                  }
                  max={
                    getOverviewData()
                      ? getMaxValueFromArray(totalData.sensorDataY) *
                        graphLayout[selectedOption.value].transformationFactor
                      : getMaxValueFromArray(L1Data.sensorDataY) *
                        graphLayout[selectedOption.value].transformationFactor
                  }
                  color="#2071B5"
                  svgSize="120"
                />
              </div>
            </div>
            <div className="frame-9">
              {/* <p class="p">{graphLayout[selectedOption.value].layout.parameter} just nu - realtid</p> */}
              <p className="p">Just nu - realtid</p>
              <div className="element">
                {getOverviewData()
                  ? getLatestRead(totalData)
                  : getLatestRead(L1Data)}{" "}
                {graphLayout[selectedOption.value].layout.unit}
              </div>
            </div>
          </div>
          {/* <CurrentSpotPrice /> */}
          <div className="pill-tab-icon-3">
            <div className="frame-10">
              <img className="img-2" src="img/chart-3.svg" />
              <div className="label-5">Spotpris</div>
            </div>
            <div className="label-6">
              {" "}
              <CurrentSpotPrice /> öre/kWh
            </div>
          </div>
          <div className="overlap-5">
            <div className="pill-tab-icon-4">
              <div className="frame-11">
                <img className="img-2" src="img/chart-3.svg" />
                <div className="label-7">
                  {/* Aktuell {graphLayout[selectedOption.value].layout.parameter} */}
                  Mätarställning
                </div>
              </div>
              <div className="label-6">
                {/* {getOverviewData()
                  ? getLatestRead(totalData)
                  : getLatestRead(L1Data)}{" "}
                {graphLayout[selectedOption.value].layout.unit} */}
                {/* {fetchSingleSensorData(userContext.AEI)} kWh */}
                <CurrentMeterStand /> kWh
              </div>
            </div>
          </div>
          <div className="tabs-container">
            <button
              className={`tab-wrapper ${activeTab === "TOTAL" ? "active" : ""}`}
              onClick={() => handleTabClick("TOTAL")}
            >
              <div className={`tab ${activeTab === "TOTAL" ? "active" : ""}`}>
                Total
              </div>
            </button>
            <button
              className={`tab-wrapper ${activeTab === "PHASE" ? "active" : ""}`}
              onClick={() => handleTabClick("PHASE")}
            >
              <div className={`tab ${activeTab === "PHASE" ? "active" : ""}`}>
                Per fas
              </div>
            </button>
          </div>
          <div className="dropdown-frame">
            <DropDownInput
              options={options}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              setTotalData={setTotalData}
              setL1Data={setL1Data}
              setL2Data={setL2Data}
              setL3Data={setL3Data}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RealTimePage;
