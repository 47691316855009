import React from "react";

import { NavLink, useLocation } from "react-router-dom";

const NaviBar = () => {
  const location = useLocation();

  return (
    <>
      <div className="navi-bar">
        <NavLink to="/home/mainpage" activeClassName="active" className="div-2">
          <div className="identification-chart">
            <img
              className="img"
              src={
                location.pathname === "/home/mainpage"
                  ? "img/home.svg"
                  : "img/home-notselected.svg"
              }
            />
          </div>
          <div className="text-wrapper-2">Översikt</div>
        </NavLink>
        <NavLink to="/home/realtime" activeClassName="active" className="div-2">
          <div className="identification-chart">
            <img
              className="img"
              src={
                location.pathname === "/home/realtime"
                  ? "img/chart.svg"
                  : "img/chart-notselected.svg"
              }
            />
          </div>
          <div className="text-wrapper-2">Realtid</div>
        </NavLink>
        <NavLink
          to="/home/spotprice"
          activeClassName="active"
          className="div-2"
        >
          <div className="identification-chart">
            <img
              className="img"
              src={
                location.pathname === "/home/spotprice"
                  ? "img/chart-2.svg"
                  : "img/chart-2-notselected.svg"
              }
            />
          </div>
          <div className="text-wrapper-2">Spotpris</div>
        </NavLink>
        <NavLink to="/home/history" activeClassName="active" className="div-2">
          <div className="identification-chart">
            <img
              className="img"
              src={
                location.pathname === "/home/history"
                  ? "img/chart-3.svg"
                  : "img/chart-3-notselected.svg"
              }
            />
          </div>
          <div className="text-wrapper-2">Historik</div>
        </NavLink>
        <NavLink
          to="/home/myaccount"
          activeClassName="active"
          className="div-2"
        >
          <div className="identification-chart">
            <img
              className="img"
              src={
                location.pathname === "/home/myaccount"
                  ? "img/user.svg"
                  : "img/user-notselected.svg"
              }
            />
          </div>
          <div className="text-wrapper-2">Mitt konto</div>
        </NavLink>
      </div>
    </>
  );
};

export default NaviBar;
