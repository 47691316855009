import React from "react";
import NotificationCard from "../components/NaviBar/NotificationCard";

const Notifications = ({ setDisplayNotification, notifications }) => {
  return (
    <>
      <div className="notifications">
        <div className="overlap-group-wrapper">
          <div className="overlap-group">
            <div className="frame">
              <div className="div">
                <div className="text-wrapper">Notiser</div>
                <button
                  className="navigation-close"
                  onClick={() => setDisplayNotification(false)}
                >
                  <img src="img/close.svg" />
                </button>
              </div>
              {notifications.length > 0 ? (
                <div>
                  <div className="notification-list">
                    {notifications.map((card) => {
                      return (
                        <NotificationCard
                          id={card.id}
                          key={card.id}
                          title={card.title}
                          body={card.body}
                          timestamp={card.timestamp}
                          active={card.active}
                        />
                      );
                    })}
                  </div>
                  <p className="text-wrapper-5">
                    Du har {notifications.length} olästa meddelanden.
                  </p>
                </div>
              ) : (
                <div className="group">
                  <div className="text-wrapper-6">
                    Det finns inga meddelanden
                  </div>
                  <img className="img" src="img/group-584.png" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;
