import React, { useState } from "react";

const ExpandableText = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div
      className={isOpen ? "frame-open" : "frame-closed"}
      onClick={toggleOpen}
    >
      {!isOpen ? (
        <>
          <div className="text-wrapper">{title}</div>
          <img className="icons-pc" src="img/down.svg" />
        </>
      ) : (
        <>
          <div className="div-2">
            <div className="text-wrapper">{title}</div>
            <img className="icons-pc" src="img/up.svg" />
          </div>
          <div className="section">
            <div className="content">{content}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default ExpandableText;
