import React, { createContext, useEffect, useState, useContext } from "react";
import { ApiContext } from "../Context/ApiContext";
import { Redirect } from "react-router-dom";
import jwt from "jwt-decode";
import { authFetch, logout } from "../Authentication/AuthenticationProvider.js";

const UserContext = createContext({
  login: "",
  display_name: "",
  authenticated: false,
});

const UserProvider = ({ children }) => {
  const { getV1Path } = useContext(ApiContext);
  const [userContext, setUserContext] = useState({
    username: "",
    login: null,
    spotPrice: null,
    display_name: "",
    authenticated: false,
    AEI: null,
    AEE: null,
    REI: null,
    REE: null,
    AEPD: null,
    IAEP: null,
    IRIP: null,
    IREP: null,
    IAIP1: null,
    IAEP1: null,
    IAIP2: null,
    IAEP2: null,
    IAIP3: null,
    IAEP3: null,
    IRIP1: null,
    IREP1: null,
    IRIP2: null,
    IREP2: null,
    IRIP3: null,
    IREP3: null,
    IV1: null,
    IV2: null,
    IV3: null,
    IC1: null,
    IC2: null,
    IC3: null,
    HEAP: null,
  });

  useEffect(() => {
    setUserData(localStorage.getItem("user"));
    console.log(userContext);
  }, []);

  const setUserData = (username) => {
    localStorage.setItem("user", username);
    const loginResponse = JSON.parse(
      localStorage.getItem("REACT_TOKEN_AUTH_KEY")
    );
    if (loginResponse !== null && loginResponse !== undefined) {
      console.log(loginResponse.username);
      setUserContext((userContext) => ({
        username: loginResponse.username,
        login: loginResponse.login,
        spotPrice: loginResponse.spotPrice || null,
        authenticated: true,

        AEI: loginResponse.AEI || null,
        AEE: loginResponse.AEE || null,
        REI: loginResponse.REI || null,
        REE: loginResponse.REE || null,
        AEPD: loginResponse.AEPD || null,
        IAEP: loginResponse.IAEP || null,
        IRIP: loginResponse.IRIP || null,
        IREP: loginResponse.IREP || null,
        IAIP1: loginResponse.IAIP1 || null,
        IAEP1: loginResponse.IAEP1 || null,
        IAIP2: loginResponse.IAIP2 || null,
        IAEP2: loginResponse.IAEP2 || null,
        IAIP3: loginResponse.IAIP3 || null,
        IAEP3: loginResponse.IAEP3 || null,
        IRIP1: loginResponse.IRIP1 || null,
        IREP1: loginResponse.IREP1 || null,
        IRIP2: loginResponse.IRIP2 || null,
        IREP2: loginResponse.IREP2 || null,
        IRIP3: loginResponse.IRIP3 || null,
        IREP3: loginResponse.IREP3 || null,
        IV1: loginResponse.IV1 || null,
        IV2: loginResponse.IV2 || null,
        IV3: loginResponse.IV3 || null,
        IC1: loginResponse.IC1 || null,
        IC2: loginResponse.IC2 || null,
        IC3: loginResponse.IC3 || null,
        HEAP: loginResponse.HEAP || null,
      }));
    }
  };

  const getAllUsers = (setUser) => {
    authFetch(getV1Path("/authentication/user/all"), {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUser(data);
      });
  };

  const checkTokenValidity = () => {
    const token = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH_KEY"));

    if (token !== null) {
      const { exp } = jwt(token.access_token);
      const expirationTime = exp * 1000 - 60000;
      if (Date.now() >= expirationTime) {
        console.log("Token expired logout process");
        logoutUnauthorized();
      }
    }
  };

  const isAdmin = () => {
    const storage = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH_KEY"));

    if (storage !== null) {
      if (storage.username === "ADMIN") {
        return true;
      }
      return false;
    }
    return false;
  };

  async function revokeSubscription() {
    console.log("revoking");
    // TODO: Generate new vapid key for server and implement in Node.js backend as well, encapsulate in well name function
    const publicVapidKey =
      "BOd2EQ8LTe3KAgMX9lWwTlHTRzv1Iantw50Mw6pUnsNr3pcxl8iglUs-YlQEQLo4UbJk9oyXs_BxgyAe0TCqKME";
    const register = await navigator.serviceWorker.getRegistration();
    if (register) {
      const subscription = await register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: publicVapidKey,
      });
      const url = getV1Path(`/logout`);

      await authFetch(url, {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(subscription),
      });
    }
  }

  const logoutUnauthorized = async () => {
    logout();
    removeUserData();
    await revokeSubscription();
    return <Redirect to="/login" />;
  };

  const removeUserData = () => {
    setUserContext((userContext) => ({
      username: "",
      login: "",
      spotPrice: null,
      display_name: "",
      authenticated: false,
      AEI: null,
      AEE: null,
      REI: null,
      REE: null,
      AEPD: null,
      IAEP: null,
      IRIP: null,
      IREP: null,
      IAIP1: null,
      IAEP1: null,
      IAIP2: null,
      IAEP2: null,
      IAIP3: null,
      IAEP3: null,
      IRIP1: null,
      IREP1: null,
      IRIP2: null,
      IREP2: null,
      IRIP3: null,
      IREP3: null,
      IV1: null,
      IV2: null,
      IV3: null,
      IC1: null,
      IC2: null,
      IC3: null,
      HEAP: null,
    }));
  };

  return (
    <UserContext.Provider
      value={{
        userContext,
        setUserData,
        removeUserData,
        logoutUnauthorized,
        checkTokenValidity,
        isAdmin,
        getAllUsers,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
