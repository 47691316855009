import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomWeekPicker = ({ startWeek, setStartWeek }) => {
  // const [startDate, setStartDate] = useState(new Date());
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="custom-date-picker" onClick={onClick} ref={ref}>
      {value}
      <img className={"calendar-icon"} src={"img/calendar-31.svg"} />
    </button>
  ));
  ExampleCustomInput.displayName = "ExampleCustomInput";

  return (
    <DatePicker
      selected={startWeek}
      onChange={(date) => setStartWeek(date)}
      customInput={<ExampleCustomInput />}
      withPortal
      dateFormat="I/R"
      locale="en-GB"
      showWeekNumbers
      showWeekPicker
    />
  );
};

export default CustomWeekPicker;
