import React from "react";

const GaugeMini = ({ value, max, color, svgSize }) => {
  const strokeWidth = (svgSize / 10) * 2; // This is the thickness of the gauge relative to the SVG size
  const radius = svgSize / 2 - strokeWidth / 2; // This makes the radius relative to the SVG size
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (value / max) * circumference;

  return (
    <svg width="100%" height="100%" viewBox={`0 0 ${svgSize} ${svgSize}`}>
      <circle
        stroke={color}
        opacity={0.3}
        fill="transparent"
        strokeWidth={strokeWidth}
        r={radius}
        cx={svgSize / 2}
        cy={svgSize / 2}
      />
      <circle
        stroke={color}
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        r={radius}
        cx={svgSize / 2}
        cy={svgSize / 2}
        transform={`rotate(-90, ${svgSize / 2}, ${svgSize / 2})`}
      />
    </svg>
  );
};

export default GaugeMini;
