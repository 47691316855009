import React, { useState } from "react";
import Plot from "react-plotly.js";
import {
  transofrmToHHMM,
  convertTimeToUTC,
} from "../Helpers/FunctionTimeHelpers";

const SpotPriceGraph = ({ graphData, graphCurrentTimeLabel }) => {
  const [hoveredPoint, setHoveredPoint] = useState(undefined);
  const [hoveredData, setHoveredData] = useState(undefined);

  function alignTooltip(position) {
    if (position < 200) {
      return "0%";
    }
    return "-100%";
  }

  function getToolTip() {
    if (hoveredData === undefined) {
      return null;
    }
    return (
      <div
        style={{
          left: `${hoveredPoint?.event.clientX}px`,
          top: `${hoveredPoint?.event.clientY}px`,
          position: "fixed",
          width: "130px",
          height: "55px",
          transform: `translateX(${alignTooltip(hoveredPoint?.event.clientX)})`,
        }}
        className="tooltip"
      >
        <div className="price">
          <div className="label"> {hoveredData.y} öre/kWh</div>
        </div>
        <div className="time">
          <img className="clock" src="img/clock.svg" />
          <div className="text-wrapper"> {transofrmToHHMM(hoveredData.x)} </div>
        </div>
      </div>
      // </div>
    );
  }

  function setUpTrace() {
    const tracesData = [];

    const data = {
      x: convertTimeToUTC(graphData.x),
      y: graphData.y,
      mode: "lines",
      line: { shape: "hv", width: 3 },
      type: "scattergl",
      fill: "tozeroy",
      marker: { line: { autocolorscale: false, colorscale: "Bluered" } },
      hovertemplate: "<extra></extra>",
    };

    tracesData.unshift(data);

    return tracesData;
  }
  if (graphData.x.length === 0) {
    return (
      <img className="empty-spotprice-graph" src="img/no-spot-price-sv.svg" />
    );
  }

  return (
    <>
      <Plot
        data={setUpTrace()}
        config={{ displayModeBar: false }}
        layout={{
          width: 374,
          height: 240,
          margin: { l: 0, r: 0, b: 0, t: 0 },
          yaxis: {
            automargin: true,
            title: { text: "Spotpris [öre/kWh]", standoff: 10 },
            showgrid: false,
            zeroline: false,
            uirevision: "time",
            side: "right",
          },
          xaxis: {
            automargin: true,
            title: { text: "Tid" },
            type: "date",
            uirevision: "time",
          },
          hovermode: "closest",

          ...(graphCurrentTimeLabel !== null && {
            shapes: [
              // 1st highlight during Feb 4 - Feb 6
              {
                type: "rect",
                // x-reference is assigned to the x-values
                xref: "x",
                // y-reference is assigned to the plot paper [0,1]
                yref: "paper",
                x0: new Date(graphCurrentTimeLabel.x1),
                y0: 0,
                x1: new Date(graphCurrentTimeLabel.x2),
                y1: 1,
                fillcolor: "#d3d3d3", //
                opacity: 0.7,
                line: {
                  width: 0,
                },
              },
            ],
          }),
        }}
        onClick={(e) => {
          if (!e.points) return;
          if (!e.points.length) return;
          if (e.points[0].pointIndex === undefined) return;
          setHoveredPoint(e);
          setHoveredData({ x: e.points[0].x, y: e.points[0].y });
        }}
        onDoubleClick={() => {
          setHoveredPoint(undefined);
          setHoveredData(undefined);
        }}
      />
      {hoveredPoint && getToolTip()}
    </>
  );
};

export default SpotPriceGraph;
