import React, { useContext, useState } from "react";
import { logout } from "../Authentication/AuthenticationProvider";
import { UserContext } from "../Context/UserContext";
import NaviBar from "./NaviBar/NaviBar";
import ModifyUserData from "./Screens/ModifyUserData";
import MainPage from "./Screens/MainPage";
import MyAccount from "./Screens/MyAccount";
import RealTimePage from "./Screens/RealTimePage";
import SpotPricePage from "./Screens/SpotPricePage";
import TopBar from "./NaviBar/TopBar";

import Notifications from "../Common/Notifications";
import HistoryPage from "./Screens/HistoryPage";

function Home(props) {
  const [notifications, setNotifications] = useState(null);
  const [displayNotification, setDisplayNotification] = useState(false);
  const { removeUserData } = useContext(UserContext);

  const onLogoutClick = (e) => {
    console.log("logout");
    removeUserData();
    logout();
  };

  const getScreen = () => {
    return {
      modifyUserData: <ModifyUserData />,
      mainpage: <MainPage />,
      realtime: <RealTimePage />,
      spotprice: <SpotPricePage />,
      history: <HistoryPage />,
      myAccount: <MyAccount />,
      default: "UNKNOWN SCRREN",
    }[props.screen];
  };

  if (displayNotification) {
    return (
      <Notifications
        notifications={notifications}
        setDisplayNotification={setDisplayNotification}
      />
    );
  }

  const OpenLinkGeFeedback = () => {
    window.open(
      "https://www.netigate.se/ra/s.aspx?s=1200013X422158932X49509",
      "_blank"
    );
  };

  return (
    <>
      <section className="home">
        <div className="home-background"></div>
        <div className="home-container">
          <div className="home-content">
            {props.screen !== "modifyUserData" &&
            props.screen !== "myAccount" ? (
              <TopBar
                setDisplayNotification={setDisplayNotification}
                setNotifications={setNotifications}
                notifications={notifications}
              />
            ) : null}
            <div className="home-main">{getScreen()}</div>
            {props.screen !== "modifyUserData" ? (
              <div className="button-usabilla" onClick={OpenLinkGeFeedback}>
                <div className="feedback">Ge feedback</div>
              </div>
            ) : null}
            <NaviBar logout={onLogoutClick} />
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
