import React, { useContext } from "react";
import { useHistory, Redirect } from "react-router-dom";
import Gauge from "../../Common/Gauge";
import { UserContext } from "../../Context/UserContext";

const MainPage = (props) => {
  const { userContext } = useContext(UserContext);

  const history = useHistory();
  const OpenRealTimePage = (e) => {
    history.push("/home/realTime");
  };
  const OpenSpotPricePage = (e) => {
    history.push("/home/spotPrice");
  };
  const OpenHistorikPage = (e) => {
    history.push("/home/history");
  };

  if (userContext.AEPD === null) {
    return null;
  }
  if (userContext.login === null) {
    return <Redirect to="/home/userData" />;
  }

  return (
    <>
      <div className="overview">
        <div className="div">
          <div className="molecules-navigation">
            <div className="frame-wrapper">
              <div className="frame-2">
                <div className="title">Välkommen!</div>
              </div>
            </div>
          </div>
          <div className="frame-3">
            <div className="overlap-2">
              <button className="frame-4" onClick={OpenRealTimePage}>
                <img className="renewable-energy" src="img/chart.svg" />
                <div className="title-2">Realtid</div>
                <div className="see-details">se graf</div>
                <img className="icons-pc" src="img/icons-pc.svg" />
              </button>
              <div className="text-wrapper-3">Nu, realtid</div>
            </div>
            <div className="numbers">
              <Gauge
                unit="W"
                sensor={userContext.AEPD}
                spotPrice={null}
                type="CurrentConsumption"
                color="#2071B5"
                svgSize="120"
                transformationFactor={1000}
              />
            </div>
          </div>
          <div className="frame-5">
            <div className="overlap-2">
              <button className="frame-4" onClick={OpenSpotPricePage}>
                <img className="cockpit" src="img/chart-2.svg" />
                <div className="title-2">Spotpris</div>
                <div className="see-details-2">se graf</div>
                <img className="icons-pc-2" src="img/icons-pc.svg" />
              </button>
              <p className="idag-exkl-moms-och">
                <span className="text-wrapper-5">Timpris, </span>
                <span className="text-wrapper-6">just nu</span>
              </p>
            </div>
            <div className="overlap-group-wrapper">
              <Gauge
                unit="öre/kWh"
                sensor={userContext.AEI}
                spotPrice={userContext.spotPrice}
                type="SpotPrice"
                color="#3DC07C"
                svgSize="120"
                transformationFactor={1}
              />
            </div>
          </div>
          <div className="overlap-4">
            <div className="frame-7">
              <div className="overlap-2">
                <button className="frame-4" onClick={OpenHistorikPage}>
                  <img className="cockpit" src="img/chart-3.svg" />
                  <div className="title-2">Historik</div>
                  <div className="see-details-2">se graf</div>
                  <img className="icons-pc-2" src="img/icons-pc.svg" />
                </button>
                <div className="text-wrapper-7">Igår</div>
              </div>
              <div className="div-wrapper">
                <Gauge
                  unit="kWh"
                  sensor={userContext.AEI}
                  spotPrice={null}
                  type="YesterdayConsumption"
                  color="#9B62C3"
                  svgSize="120"
                  transformationFactor={1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainPage;
