import React, { useContext } from "react";
import { UserContext } from "../Context/UserContext";

const LogoutButton = (props) => {
  const { logoutUnauthorized } = useContext(UserContext);

  return (
    <>
      <div className="text-link" onClick={logoutUnauthorized}>
        <div className="text-label">Logga ut</div>
      </div>
    </>
  );
};
export default LogoutButton;
