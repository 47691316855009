import React from "react";

const CloseModalButton = ({ setCloseModal }) => {
  return (
    <div className="official-button">
      <button className="button" onClick={() => setCloseModal(true)}>
        <div className="spacing">
          <div className="label">Confirm</div>
        </div>
      </button>
    </div>
  );
};

export default CloseModalButton;
