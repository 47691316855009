import React from "react";
import { useHistory } from "react-router-dom";

const Start = () => {
  const history = useHistory();
  const onLoginClick = (e) => {
    history.push("/login");
  };
  const onManualClick = (e) => {
    history.push("/manual");
  };

  return (
    <>
      <div className="START">
        <div className="div">
          <div className="stacked-logos">
            <img className="boolean-group" src="img/boolean-group.svg" />
            <img className="logos-symbol-large" src="img/large.png" />
          </div>
          <div className="interaction-buttons">
            <button className="buttons" onClick={onLoginClick}>
              <div className="button-label">Logga in</div>
            </button>
            <div className="link">
              <button className="button" onClick={onManualClick}>
                <img className="icon" src="img/icon.svg" />
                <div className="label">Konfigurera din enhet (Manual)</div>
              </button>
            </div>
          </div>
          <div className="text-wrapper">SAVER - Pilot</div>
        </div>
      </div>
    </>
  );
};

export default Start;
