import React, { useState } from "react";

const Toggle = ({ onToggle }) => {
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
    onToggle(!isActive);
  };

  return (
    <div className="toggle">
      <div
        className={`toggle ${isActive ? "active" : ""}`}
        onClick={handleToggle}
      >
        <div className="switch-off-default">
          <div className="switch-off"></div>
        </div>
      </div>
    </div>
  );
};

export default Toggle;
