import React, { createContext, useState } from "react";

const ApiContext = createContext("");

const ApiProvider = ({ children }) => {
  console.log(window.runConfig);
  const [apiData] = useState({
    url: window.runConfig.apiUrl,
    refreshRate: window.runConfig.refreshRate,
  });

  const getV1Path = (relativePath) => {
    return apiData.url + "/api/v1" + relativePath;
  };

  const V1Path = apiData.url + "/api/v1";

  return (
    <ApiContext.Provider value={{ apiData, getV1Path, V1Path }}>
      {children}
    </ApiContext.Provider>
  );
};

export { ApiProvider, ApiContext };
