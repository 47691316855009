import React, { useState } from "react";

const GenericDropDown = ({
  setSelectedOption,
  selectedOption,
  options,
  setErrorText,
  setErrorTextHide,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onChangeClick = async (option) => {
    // e.preventDefault()
  };

  return (
    <div className={isOpen ? "dropdown-spotprice-with" : "dropdown-spotprice"}>
      <div className="skeleton" onClick={() => setIsOpen(!isOpen)}>
        <div className="select-option">
          {isOpen
            ? "Välj per."
            : selectedOption
            ? selectedOption.label
            : options[2].label}
        </div>
        <img
          className={isOpen ? "icons-navigation-up" : "icons-symbol-arrow"}
          src={isOpen ? "img/up.svg" : "img/down.svg"}
        />
      </div>
      {isOpen && (
        <div>
          {options.map((option) => (
            <div
              key={option.value}
              className={`link ${
                selectedOption.value === option.value ? "selected" : ""
              }`}
            >
              <div
                className={`winter-spring-summer ${
                  selectedOption.value === option.value ? "selected" : ""
                }`}
                onClick={(e) => {
                  setSelectedOption(option);
                  setIsOpen(false);
                  onChangeClick(option);
                }}
              >
                {option.label}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GenericDropDown;
