import React from "react";

const InfoModal = ({ header, text, children }) => {
  return (
    <div className="info-message">
      <div className="heading">
        <div className="frame-2">
          <img className="information" src="img/information.svg" />
          <p className="element">{header}</p>
        </div>
      </div>
      <div className="content">
        <p className="element-a">{text}</p>
      </div>
      {children}
    </div>
  );
};

export default InfoModal;
