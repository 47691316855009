import React, { useState } from "react";

const DropDownInput = ({
  options,
  setSelectedOption,
  selectedOption,
  setTotalData,
  setL1Data,
  setL2Data,
  setL3Data,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  // const handleOptionClick = (optionValue) => {
  //     setSelectedOption(optionValue);
  //     setIsOpen(false);
  // };

  // const selectedOption = options.find(option => option.value === value.value);

  return (
    <div className={isOpen ? "dropdown-with" : "dropdown"}>
      <div className="skeleton" onClick={() => setIsOpen(!isOpen)}>
        <div className="select-option">
          {isOpen
            ? "Välj per."
            : selectedOption
            ? selectedOption.label
            : "Effekt (W)"}
        </div>
        <img
          className={isOpen ? "icons-navigation-up" : "icons-symbol-arrow"}
          src={isOpen ? "img/up.svg" : "img/down.svg"}
        />
      </div>
      {isOpen && (
        <div>
          {options.map((option) => (
            <div
              key={option.value}
              className={`link ${
                selectedOption.value === option.value ? "selected" : ""
              }`}
            >
              <div
                className={`winter-spring-summer ${
                  selectedOption.value === option.value ? "selected" : ""
                }`}
                onClick={() => {
                  setSelectedOption(option);
                  setIsOpen(false);
                  setL1Data({});
                  setL2Data({});
                  setL3Data({});
                  setTotalData({});
                }}
              >
                {option.label}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDownInput;
