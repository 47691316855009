export function extractSensorID(code) {
  if (code !== null) {
    const sensorID = code.split("-").slice(-1)[0];
    return sensorID;
  }
  return "empty";
}

export function getMaxValueFromArray(data) {
  if (data !== undefined && data.length > 0) {
    return Math.max(...data);
  }
}

export function getMinValueFromArray(data) {
  if (data !== undefined && data.length > 0) {
    return Math.min(...data);
  }
}
